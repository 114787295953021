<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12 box-login">
              <v-form ref="formLogin" @submit.prevent="onSubmit" lazy-validation>
              <!-- <v-toolbar dark class="title-login">
                <v-toolbar-title class="title-login-logo">
                  <img
                    src="https://cartaomais.jopdesigner.com.br/assets/img/logo-header.jpg"
                    height="64px"
                  />
                </v-toolbar-title>
              </v-toolbar> -->
              <v-card-text>
                <p class="login-box-msg">
                  Utilize suas credencias para acessar o sistema.
                </p>
                
                  <v-text-field
                    prepend-icon="mdi-account"
                    label="Usuário"
                    type="text"
                    v-model="user"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                    autocomplete="username"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-lock"
                    label="Senha"
                    type="password"
                    v-model="pass"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                    autocomplete="current-password"
                  ></v-text-field>
                
                <p v-if="error">
                  {{ message }}
                </p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="teal"
                  type="submit"
                  dark
                  submit
                  >Entrar</v-btn
                >
              </v-card-actions>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { isSignedIn, signIn, authMsg } from "../functions/auth";
import router from '../router'

export default {
  name: "Login",
  data: () => ({
    user: null,
    pass: null,
    message: "",
    error: false,
  }),
  methods: {
    async onSubmit() {
      if (this.$refs.formLogin.validate()) {
        if (this.user && this.pass) {
          await signIn(this.user, this.pass);
          if (isSignedIn()) {
            router.replace('/home')
          } else {
            this.error = true;
            this.message = authMsg.message;
          }
        } else {
          this.error = true;
          this.message = "Verifique usuário e senha";
          this.$refs.formLogin.validate();
        }
      }
    },
  },
  mounted() {
    if (isSignedIn()) {
      router.replace("/");
    }
  },
};
</script>

<style>
.title-login {
  background-color: #218b2a !important;
}
.title-login,
.title-login > div,
.title-login-logo {
  height: auto !important;
}
.v-toolbar__content {
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}
</style>