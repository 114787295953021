<template>
  <v-card elevation="2" dark class="mx-auto teal darken-2" width="250px">
    <v-card-title class=" valores"> {{ total }} </v-card-title>
    <v-card-text class=" font-weight-bold"> Clientes </v-card-text>
  </v-card>
</template>
<style></style>
<script>
import api from '../plugins/Api';
import swal from 'sweetalert2';

export default {
  name: 'counterClients',
  props: {},
  data: () => ({
    urlBase: '/clients',
    isLoading: false,
    total: 0
  }),
  methods: {
    async counter() {
      let vm = this;
      this.isLoading = true;
      await api
        .get(`${vm.urlBase}/counter`)
        .then(function(response) {
          if (response.status == 200) {
            vm.total = response.data[0].countAll;
          } else {
            swal
              .mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 5000
              })
              .fire({
                icon: 'error',
                title: response.data.message
              });
          }
        })
        .catch(function(err) {
          swal
            .mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 5000
            })
            .fire({
              icon: 'error',
              title: err.response.data.message
            });
        });
      this.isLoading = false;
    }
  },
  created: function() {
    this.counter();
  }
};
</script>
