import axios from 'axios'
import router from '../router';
import { signOut } from '../functions/auth';



const api = axios.create({
  baseURL: 'https://api.e-cicla.com.br/v1',
  // baseURL: 'https://cartaomais.jopdesigner.com.br/api',
  // baseURL: 'http://localhost:3000/v1',
  timeout: 5000,
});

// (function() {
//   let token = localStorage.getItem(token)
//   if (token) {
//       api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
//   } else {
//       api.defaults.headers.common['Authorization'] = null;
//   }
// })();

// api.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token
api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
api.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';

api.interceptors.request.use(
  function (config) {
    // if (!isSignedIn()) signOut();

    if (!config.url.match(/viacep/)) {
      const token = localStorage.getItem('token');
      config.headers.Authorization = token ? `Bearer ${token}` : '';
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
      if (error.response.status === 401) {
        signOut()
        router.push('/login')
        // return Promise.reject(error.response);
      }
    return Promise.reject(error);
  }
);

export default api