import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
// import swal from 'sweetalert2'
import VueTheMask from 'vue-the-mask'
import money from 'v-money'

import { VueMaskFilter } from 'v-mask'

// import 'sweetalert2/dist/sweetalert2.min.css';
import './styles/base.css'

import VueCurrencyFilter from 'vue-currency-filter'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'

Vue.component('v-text-field', VTextField)

Vue.use(VueCurrencyFilter, {
  symbol: 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
});

Vue.use(VCurrencyField, {
  locale: 'pt-BR',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false
})


// Vue.use(swal);
Vue.use(VueTheMask)
Vue.use(money, {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  suffix: "",
  precision: 2,
  masked: false,
})

Vue.config.productionTip = false

Vue.filter('VMask', VueMaskFilter)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')