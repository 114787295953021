<template>
  <div>
    <v-main>
      <v-container fluid>
        <v-card elevation="2">
          <v-toolbar dark color="teal darken-2" elevation="0">
            <v-toolbar-title class="caption">{{
              "Contas a Receber" | uppercase
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-title>
            <v-btn
              color="teal lighten-1"
              rounded
              small
              class="sm-2 white--text"
              @click="viewModal()"
            >
              <v-icon small left>mdi-plus-circle</v-icon>
              Nova Conta
            </v-btn>
            <v-spacer></v-spacer>
            <v-col class="d-flex">
              <v-card elevation="2" dark class="teal darken" width="250px">
                <v-card-title class=""> R$ 0 </v-card-title>
                <v-card-text class="font-weight-bold">
                  Total a Receber - 7 dias
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="d-flex">
              <v-card elevation="2" dark class="teal darken" width="250px">
                <v-card-title class=""> R$ 0 </v-card-title>
                <v-card-text class="font-weight-bold">
                  Total a Receber - Mês
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="d-flex">
              <v-card elevation="2" dark class="teal darken" width="250px">
                <v-card-title class=""> R$ 0 </v-card-title>
                <v-card-text class="font-weight-bold">
                  Total Vencido - Mês
                </v-card-text>
                <!-- <span class="caption white--text font-weight-light">Visualizar</span> -->
              </v-card>
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headerProps"
            :items="listContas"
            :search="search"
            class="elevation-1"
            :footer-props="footerProps"
            :loading="isLoading"
            loading-text="Carregando..."
          >
            <template v-slot:[`item.descricao`]="{ item }">
              {{ item.descricao | uppercase }}
            </template>
            <template v-slot:[`item.valor`]="{ item }">
              R$ {{ item.valor | moneyBR}}
            </template>
            <template v-slot:[`item.vencimento`]="{ item }">
              {{ item.vencimento | moment("DD/MM/YYYY") }}
            </template>
            <template v-slot:[`item.situacao`]="{ item }">
              <span class="caption">{{ item.situacao | uppercase }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <!-- <v-btn color="primary" rounded x-small class="sm-2 white--text">
                <v-icon x-small left>mdi-eye</v-icon>
                Visualizar
              </v-btn> -->
              <v-btn
                color="orange accent-3"
                fab
                x-small
                class="mx-1 white--text"
                @click="viewModal('update', item)"
              >
                <v-icon small> mdi-pencil </v-icon>
              </v-btn>
              <v-btn color="red accent-3" fab x-small class="mx-1 white--text">
                <v-icon small> mdi-delete </v-icon>
              </v-btn>
            </template>
            <template slot="no-data"> Nenhuma informação há exibir. </template>
            <template slot="no-results">
              Nenhuma informação há exibir.
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </v-main>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
      <v-card>
        <v-toolbar dark color="teal lighten-1" elevation="0">
          <v-toolbar-title class=""
            >{{ modalTitle }} - #{{ parseInt(conta.id * 100) }}</v-toolbar-title
          >
        </v-toolbar>
        <!-- <v-card-title>
          <span class="headline">{{ modalTitle }}</span>
        </v-card-title> -->
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="conta.descricao"
                    label="Descrição: *"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    type="date"
                    v-model="conta.vencimento"
                    label="Data Vencimento: *"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Valor: *"
                    v-model="conta.valor"
                    v-bind="money"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="conta.formapgto"
                    :items="[
                      { id: 'd', name: 'Dinheiro' },
                      { id: 'dc', name: 'Débito em Conta' },
                      { id: 'cc', name: 'Cartao de Crédito' },
                    ]"
                    label="Forma de Pagamento: *"
                    item-value="id"
                    item-text="name"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*Campos obrigatórios</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="grey" small outlined reset @click="reset">
            {{ modalBtnCancel }}
          </v-btn>
          <v-btn
            dark
            rounded
            v-if="modalType == 'insert'"
            color="teal lighten-1"
            submit
            @click="insertConta()"
          >
            Cadastrar
          </v-btn>
          <v-btn
            dark
            rounded
            v-else-if="modalType == 'update'"
            color="teal lighten-1"
            submit
            @click="updateConta()"
          >
            Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { VMoney } from "v-money";
export default {
  filters: {
    uppercase: function (text) {
      return text.toUpperCase();
    },
    moneyBR: function (price){
      return price.toLocaleString('pt-br', {minimumFractionDigits: 2});
    }
  },
  directives: { money: VMoney },
  data: () => ({
    isLoading: true,
    overlay: true,
    drawer: null,
    dialog: false,
    modalTitle: "",
    modalType: "",
    modalBtnCancel: "Cancelar",
    valid: true,
    search: "",
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: false,
    },
    headerProps: [
      {
        text: "Descrição",
        sortable: true,
        value: "descricao",
      },
      {
        text: "Valor",
        sortable: true,
        value: "valor",
      },
      {
        text: "Vencimento",
        sortable: false,
        value: "vencimento",
      },
      {
        text: "Situação",
        sortable: false,
        value: "situacao",
      },
      {
        text: "Opções",
        value: "actions",
        sortable: false,
        align: "end",
      },
    ],
    footerProps: {
      "items-per-page-text": "Registros por página",
      "items-per-page-options": [10, 20, 30, 40, 50],
      pageText: "{0}-{1} de {2}",
      // 'disable-items-per-page': false
    },
    listContas: [
      // {
      //   id: Math.random(),
      //   descricao: "João Alberto",
      //   valor: 51.8,
      //   vencimento: "2021-10-12",
      //   situacao: "vencido",
      //   formapgto:'d'
      // },
      // {
      //   id: Math.random(),
      //   descricao: "Anderson Luiz",
      //   valor: 25.9,
      //   vencimento: "2021-10-21",
      //   situacao: "aberto",
      //   formapgto:'d'
      // },
      // {
      //   id: Math.random(),
      //   descricao: "Marinalva Solimões",
      //   valor: 25.9,
      //   vencimento: "2021-10-21",
      //   situacao: "aberto",
      //   formapgto:'d'
      // },
      // {
      //   id: Math.random(),
      //   descricao: "Bethânia Alburque",
      //   valor: 25.9,
      //   vencimento: "2021-10-22",
      //   situacao: "aberto",
      //   formapgto:'d'
      // },
      // {
      //   id: Math.random(),
      //   descricao: "Renata Soares Souza Santos",
      //   valor: 25.9,
      //   vencimento: "2021-10-23",
      //   situacao: "aberto",
      //   formapgto:'d'
      // },
    ],
    conta: {
      id: "",
      descricao: "",
      valor: 0,
      vencimento: "",
      situacao: "",
      formapgto:''
    },
  }),
  methods: {
    toggleOverlay() {
      this.overlay = !this.overlay;
    },
    moneyBR: (price)=>{
      return price.toLocaleString('pt-br', {minimumFractionDigits: 2});
    },
    reset() {
      this.dialog = false;
      // this.client.id = "";
      // this.client.name = "";
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    getContas() {
      // vm.isLoading = true;
      // console.log("dentro do get");
      // await vm.$api
      //   .get("/controllers/Clients.php?f=l")
      //   .then(function (response) {
      //     if (response.data.success) {
      //       // console.table(response.data.data)
      //       vm.listClients = response.data.data;
      //     }
      //   })
      //   .catch(function () {

      // this.$swal
      //   .mixin({
      //     toast: true,
      //     position: "bottom-end",
      //     showConfirmButton: false,
      //     timer: 5000,
      //   })
      //   .fire({
      //     icon: "error",
      //     title: "Não há dados há exibir",
      //   });
      //   });
      this.isLoading = false;
    },
    insertConta() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        let new_conta = {
          id: Math.random(),
          descricao: this.conta.descricao,
          valor: this.conta.valor,
          vencimento: this.conta.vencimento,
          formapgto: this.conta.formapgto,
          situacao: 'aberto',
        };
        this.listContas.push(new_conta);
        this.isLoading = false;
        this.reset();
      }
      // await this.axios
      //   .post("/controllers/Clients.php?f=i", params, {
      //     "content-type": "application/x-www-form-urlencoded",
      //   })
      //   .then(function (response) {
      //     this.isLoading = false;
      //     if (response.data.success) {
      //       this.Swal.mixin({
      //         toast: true,
      //         position: "bottom-end",
      //         showConfirmButton: false,
      //         timer: 5000,
      //       }).fire({
      //         icon: "success",
      //         title: response.data.message,
      //       });
      //       this.getClients();
      //       this.reset();
      //     } else {
      //       this.Swal.mixin({
      //         toast: true,
      //         position: "bottom-end",
      //         showConfirmButton: false,
      //         timer: 5000,
      //       }).fire({
      //         icon: "error",
      //         title: response.data.message,
      //       });
      //     }
      //   })
      //   .catch(function () {
      //     this.isLoading = false;
      //     this.Swal.mixin({
      //       toast: true,
      //       position: "bottom-end",
      //       showConfirmButton: false,
      //       timer: 5000,
      //     }).fire({
      //       icon: "error",
      //       title: "Erro Inesperado.",
      //     });
      //   });
    },
    updateConta() {
      //this.isLoading = true;
      this.$swal
        .fire({
          title: "Deseja atualizar este registro?",
          text: "Não é possível desfazer esta ação.",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then(function () {
          // if (result.value) {
          //   const params = new URLSearchParams();
          //   params.append("id", this.client.id);
          //   params.append("name", this.client.name);
          //   params.append("cpf", this.client.cpf);
          //   params.append("docCIC", this.client.docCIC);
          //   params.append("birthDate", this.client.birthDate);
          //   params.append("gender", this.client.gender);
          //   await this.axios
          //     .post("/controllers/Clients.php?f=u", params, {
          //       "content-type": "application/x-www-form-urlencoded",
          //     })
          //     .then(function (response) {
          //       this.isLoading = false;
          //       if (response.data.success) {
          //         this.swal.fire(
          //           "Atualizado!",
          //           "Os dados foram atualizados com sucesso.",
          //           "success"
          //         );
          //         this.getClients();
          //         this.modalBtnCancel = "Sair";
          //       } else {
          //         this.swal.fire("Falha!", response.data.message, "warning");
          //       }
          //     })
          //     .catch(function () {
          //       this.isLoading = false;
          //       this.Swal.mixin({
          //         toast: true,
          //         position: "bottom-end",
          //         showConfirmButton: false,
          //         timer: 5000,
          //       }).fire({
          //         icon: "error",
          //         title: "Erro Inesperado.",
          //       });
          //     });
          //}
        });
      this.reset();
    },
    viewModal(type = "insert", conta = "null") {
      console.log(conta)
      this.modalBtnCancel = "Cancelar";
      if (type === "update") {
        this.modalTitle = "Editar Conta";
        this.conta.id = Math.random();
        this.conta.descricao = conta.descricao;
        this.conta.valor = this.moneyBR(conta.valor);
        this.conta.vencimento = conta.vencimento;
        this.conta.formapgto = conta.formapgto;
      } else {
        this.modalTitle = "Cadastrar Conta";
        this.conta.id = 1;
      }

      this.modalType = type;
      this.dialog = true;
    },
  },
  created: function () {},
  mounted: function () {
    this.getContas();
    this.toggleOverlay();
  },
};
</script>