<template>
  <div>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col class="d-flex">
            <CardClientCounter/>
          </v-col>
          <v-col class="d-flex">
            <v-card elevation="2" dark class="mx-auto teal darken-2 " width="250px">
              <v-card-title class=" valores"> 0 </v-card-title>
              <v-card-text class=" font-weight-bold"> Contratos Ativos </v-card-text>
            </v-card>
          </v-col>
          <v-col class="d-flex">
            <v-card elevation="2" dark class="mx-auto teal darken-2 " width="250px">
              <v-card-title class=" valores"> 0 </v-card-title>
              <v-card-text class=" font-weight-bold"> Contratos Vencidos </v-card-text>
            </v-card>
          </v-col>
          <v-col class="d-flex">
            <v-card elevation="2" dark class="mx-auto teal darken-2" width="250px">
              <v-card-title class=" valores"> R$ 0 </v-card-title>
              <v-card-text class=" font-weight-bold"> Contas a Pagar </v-card-text>
            </v-card>
          </v-col>
          <v-col class="d-flex">
            <v-card elevation="2" dark class="mx-auto teal darken-2" width="250px">
              <v-card-title class=" valores"> R$ 0 </v-card-title>
              <v-card-text class=" font-weight-bold"> Contas a Receber </v-card-text>
              <!-- <span class="caption white--text font-weight-light">Visualizar</span> -->
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>
<style>
.valores{
  font-size: 2em;
}
</style>

<script>
import CardClientCounter from "../components/CardClientCounter.vue";

export default {
  name: "Home",
  components:{
    CardClientCounter
  }
};
</script>
