<template>
  <v-app>
    <div v-if="$route.meta.navBarEnable">
      <MenuPrincipal />
    </div>
    <router-view/>
  </v-app>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 10px;
}
</style>
<script>
import MenuPrincipal from './components/MenuPrincipal.vue'

export default ({
  components: {
    MenuPrincipal
  },
  data: ()=>({
    
  })
})
</script>
