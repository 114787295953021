<template>
  <div>
    <v-main>
      <v-container fluid>
        <v-card elevation="2">
          <v-toolbar dark color="teal darken-2" elevation="0">
            <v-toolbar-title class="caption">{{
              'Usuários do Sistema' | uppercase
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-title>
            <v-btn
              color="teal lighten-1"
              rounded
              small
              class="sm-2 white--text"
              @click="viewModal()"
            >
              <v-icon small left>mdi-plus-circle</v-icon>
              Novo Usuário
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headerProps.header"
            :header-props="headerProps.props"
            :items="listStaff"
            :search="search"
            class="elevation-1"
            :footer-props="footerProps"
            loading="isLoading"
            loading-text="Carregando..."
          >
            <template v-slot:[`item.users_name`]="{ item }">
              {{ item.users_name | uppercase }}
            </template>
            <template v-slot:[`item.users_mail`]="{ item }">
              {{ item.users_mail | uppercase }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <!-- <v-btn color="primary" rounded x-small class="ma-2 white--text">
                <v-icon x-small left>mdi-eye</v-icon>
                Visualizar
              </v-btn> -->
              <v-btn
                color="red"
                rounded
                x-small
                class="ma-2 white--text"
                @click="viewModal('update', item)"
              >
                <v-icon x-small left> mdi-pencil </v-icon>
                Editar
              </v-btn>
            </template>
            <template slot="no-data"> Nenhuma informação há exibir. </template>
            <template slot="no-results">
              Nenhuma informação há exibir.
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </v-main>
    <v-dialog v-model="dialog" persistent scrollable max-width="700px">
      <v-card>
        <v-toolbar dark color="teal lighten-1" elevation="0">
          <v-toolbar-title class="">{{ modalTitle }} </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="staff.name"
                    label="Nome: *"
                    :rules="[v => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="staff.cpf"
                    label="CPF: *"
                    v-mask="'###.###.###-##'"
                    :rules="cpfRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    type="date"
                    v-model="staff.birthDate"
                    label="Data Nascimento: *"
                    :rules="birthRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="staff.email"
                    label="E-mail: *"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Telefone: *"
                    v-mask="['(##) #####-####', '(##) ####-####']"
                    :rules="[v => !!v || 'Campo obrigatório']"
                    required
                    v-model="staff.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="staff.accessLevel"
                    :items="[
                      { id: 1, name: 'Usuário' },
                      { id: 10, name: 'Administrador' }
                    ]"
                    label="Nível de Acesso: *"
                    required
                    :rules="[v => !!v || 'Campo obrigatório']"
                    item-value="id"
                    item-text="name"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-if="modalType == 'insert'">
                <v-col cols="4">
                  <v-text-field
                    v-model="staff.login"
                    label="Usuario: *"
                    :rules="[v => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="staff.passwd"
                    label="Senha: *"
                    :rules="passRules"
                    required
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="staff.repasswd"
                    label="Re-Senha: *"
                    :rules="[repassRules.required, confirmPass]"
                    required
                    type="password"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-else-if="modalType == 'update'">
                <v-col cols="4">
                  <v-text-field
                    v-model="staff.login"
                    label="Usuario: *"
                    :rules="[v => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Senha: " disabled></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Re-Senha: " disabled></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*Campos obrigatórios</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="grey" small outlined reset @click="reset">
            {{ modalBtnCancel }}
          </v-btn>
          <v-btn
            class="ma-2"
            dark
            rounded
            v-if="modalType == 'insert'"
            color="teal lighten-1"
            submit
            @click="insertStaff()"
          >
            Cadastrar
          </v-btn>
          <v-btn
            class="ma-2"
            dark
            rounded
            v-else-if="modalType == 'update'"
            color="teal lighten-1"
            submit
            @click="updateStaff()"
          >
            Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import api from '../plugins/Api';
import swal from 'sweetalert2';
import moment from 'moment';
import { validateCpf } from '../functions/validation';

export default {
  filters: {
    uppercase: function(text) {
      return text.toUpperCase();
    },
    dateFormat: function(date) {
      return date
        ? moment(date, 'YYYY-MM-DD T00:00:00').format('DD/MM/YYYY')
        : date;
    }
  },
  data: () => ({
    urlBase: '/users',
    isLoading: false,
    overlay: true,
    drawer: null,
    dialog: false,
    modalTitle: '',
    modalType: '',
    modalBtnCancel: 'Cancelar',
    valid: true,
    search: '',
    eyepass: true,
    headerProps: {
      props: {
        sortByText: 'Ordenar por:'
      },
      header: [
        {
          text: 'Nome',
          sortable: true,
          value: 'users_name'
        },
        {
          text: 'E-mail',
          sortable: true,
          value: 'users_mail'
        },
        {
          text: 'Telefone',
          sortable: false,
          value: 'users_phone'
        },
        {
          text: 'Opções',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ]
    },
    footerProps: {
      'items-per-page-text': 'Registros por página',
      'items-per-page-options': [10, 20, 30, 40, 50],
      pageText: '{0}-{1} de {2}'
      // 'disable-items-per-page': false
    },
    listStaff: [],
    staff: {
      id: '',
      name: '',
      login: '',
      passwd: '',
      repasswd: '',
      email: '',
      phone: '',
      cpf: '',
      birthDate: '',
      accessLevel: ''
    },

    cpfRules: [
      v => !!v || 'Campo obrigatório',
      v => !!validateCpf(v) || 'CPF inválido'
    ],
    emailRules: [
      v => !!v || 'E-mail é obrigatório',
      v => /.+@.+\..+/.test(v) || 'E-mail inválido'
    ],
    birthRules: [
      v => !!v || 'Data de Nascimento é obrigatória',
      v => !!new Date(v) || `'Data inválida`,
      v =>
        new Date(moment(v).format('YYYY-MM-DD 23:59:59')) < new Date() ||
        'Data inválida'
    ],
    passRules: [
      v => !!v || 'Informe uma senha',
      v => (v && v.length >= 8) || 'Deve ter pelo menos 8 caracteres',
      v => /(?=.*[A-Za-z])/.test(v) || 'Ao menos uma letra',
      v => /(?=.*\d)/.test(v) || 'Ao menos um caracter Numérico'
    ],
    repassRules: {
      required: v => !!v || 'Digite a senha novamente'
    }
  }),
  methods: {
    toggleOverlay() {
      this.overlay = !this.overlay;
    },
    reset() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    async getStaff() {
      let vm = this;
      this.isLoading = true;

      await api
        .get(this.urlBase)
        .then(function(response) {
          if (response.status == 200) {
            vm.listStaff = response.data;
          } else {
            swal
              .mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 5000
              })
              .fire({
                icon: 'error',
                title: response.data.message
              });
          }
        })
        .catch(error => {
          swal
            .mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 5000
            })
            .fire({
              icon: 'error',
              title: error.response.data.message
            });
        });
      this.isLoading = false;
    },
    async insertStaff() {
      let vm = this;
      if (vm.$refs.form.validate()) {
        vm.isLoading = true;
        const params = new URLSearchParams();
        params.append('name', vm.staff.name);
        params.append('cpf', vm.staff.cpf);
        params.append('email', vm.staff.email);
        params.append('phone', vm.staff.phone);
        params.append('birthDate', vm.staff.birthDate);
        params.append('login', vm.staff.login);
        params.append('passwd', vm.staff.passwd);
        params.append('accessLevel', vm.staff.accessLevel);

        await api
          .post(vm.urlBase, params)
          .then(function(response) {
            if (response.status === 201) {
              swal
                .mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 5000
                })
                .fire({
                  icon: 'success',
                  title: 'Cadastrado com sucesso!'
                });
              vm.getStaff();
              vm.reset();
            }
          })
          .catch(error => {
            swal
              .mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 5000
              })
              .fire({
                icon: 'error',
                title: error.response.data.message
              });
          });
        this.isLoading = false;
      }
    },
    async updateStaff() {
      let vm = this;
      vm.isLoading = true;
      if (this.$refs.form.validate()) {
        swal
          .fire({
            title: 'Deseja atualizar este registro?',
            text: 'Atualizar dados do usuário.',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          })
          .then(async function(result) {
            if (result.value) {
              const params = new URLSearchParams();
              params.append('name', vm.staff.name);
              params.append('cpf', vm.staff.cpf);
              params.append('email', vm.staff.email);
              params.append('phone', vm.staff.phone);
              params.append('birthDate', vm.staff.birthDate);
              params.append('login', vm.staff.login);
              params.append('accessLevel', vm.staff.accessLevel);

              await api
                .put(`${vm.urlBase}/${vm.staff.id}`, params)
                .then(function(response) {
                  if (response.status === 204) {
                    swal.fire(
                      'Atualizado!',
                      'Os dados foram atualizados com sucesso.',
                      'success'
                    );
                    vm.getStaff();
                    vm.modalBtnCancel = 'Sair';
                  } else {
                    swal.fire('Falha!', response.data.message, 'warning');
                  }
                })
                .catch(function(error) {
                  swal
                    .mixin({
                      toast: true,
                      position: 'bottom-end',
                      showConfirmButton: false,
                      timer: 5000
                    })
                    .fire({
                      icon: 'error',
                      title: error.response.data.message
                    });
                });
            }
          });
      }
      this.isLoading = false;
    },
    viewModal(type = 'insert', staffs = 'null') {
      this.modalBtnCancel = 'Cancelar';
      if (type === 'update') {
        this.modalTitle = 'Editar Funcionário - #' + staffs.users_id;
        this.staff.id = staffs.users_id;
        this.staff.name = staffs.users_name;
        this.staff.cpf = staffs.users_cpf;
        this.staff.login = staffs.users_login;
        this.staff.birthDate = moment(
          staffs.users_birthdate,
          'YYYY-MM-DD T00:00:00'
        ).format('YYYY-MM-DD');
        this.staff.phone = staffs.users_phone;
        this.staff.email = staffs.users_mail;
        this.staff.accessLevel = staffs.users_accessLevel;
      } else {
        this.modalTitle = 'Cadastrar Funcionário';
      }
      this.modalType = type;
      this.dialog = true;
    }
  },
  created: function() {},
  mounted: function() {
    this.getStaff();
    this.toggleOverlay();
  },
  computed: {
    confirmPass() {
      const rule =
        this.staff.repasswd === this.staff.passwd || 'Senha não confere';
      return rule;
    }
  }
};
</script>
