<template>
  <div>
    <v-card :loading="isLoading" outlined tile>
      <v-toolbar dark color="grey" elevation="0" dense>
        <v-toolbar-title>Endereço</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-2"
          right
          rounded
          small
          class="sm-2 white--text"
          @click="viewModalAddress('insert')"
        >
          <v-icon small left> mdi-plus-circle </v-icon>
          Novo Endereço
        </v-btn>
      </v-toolbar>
      <v-card-text class="">
        <v-data-table
          :headers="TableProps.header"
          :header-props="TableProps.props"
          :footer-props="TableProps.footer"
          :items="listAddress"
          class="elevation-1"
        >
          <template v-slot:[`item.address_apelido`]="{ item }">
            {{ item.address_apelido | uppercase }}
          </template>
          <template v-slot:[`item.address_cep`]="{ item }">
            {{ item.address_cep | VMask('#####-###') }}
          </template>
          <template v-slot:[`item.address_street`]="{ item }">
            {{ (item.address_street + ', ' + item.address_number) | uppercase }}
          </template>
          <template v-slot:[`item.address_district`]="{ item }">
            {{ item.address_district | uppercase }}
          </template>
          <template v-slot:[`item.address_city`]="{ item }">
            {{ item.address_city | uppercase }}
          </template>
          <template v-slot:[`item.address_uf`]="{ item }">
            {{ item.address_uf | uppercase }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="viewModalAddress('update', item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteAddress(item)"> mdi-delete </v-icon>
          </template>
          <template slot="no-data"> Nenhuma informação há exibir. </template>
          <template slot="no-results"> Nenhuma informação há exibir. </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
      <v-card>
        <v-toolbar dark color="teal lighten-1" elevation="0">
          <v-toolbar-title class="">
            {{ modalTitle }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-grey"
            rounded
            small
            class="ma-2 white--text"
            @click="searchAddressDialog = true"
          >
            <v-icon small left> mdi-magnify </v-icon>
            Buscar CEP
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="address.cep"
                    label="CEP: *"
                    v-mask="'#####-###'"
                    required
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    @blur="getPostalCodeData"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="address.street"
                    label="Endereço: *"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-text-field
                    v-model="address.number"
                    label="Nº: *"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="address.comp"
                    label="Complemento:"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="address.instNumber"
                    label="Cod. Instalação EDP:"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <v-text-field
                    v-model="address.district"
                    label="Bairro: *"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="address.city"
                    label="Cidade: *"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model="address.state"
                    label="UF: *"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="address.apelido"
                    label="Apelido: "
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*Campos obrigatórios</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="grey" small outlined reset @click="reset">
            {{ modalBtnCancel }}
          </v-btn>
          <v-btn
            dark
            rounded
            v-if="modalType == 'insert'"
            color="teal lighten-1"
            submit
            @click="insertAddress"
          >
            Cadastrar
          </v-btn>
          <v-btn
            dark
            rounded
            v-else-if="modalType == 'update'"
            color="teal lighten-1"
            submit
            @click="updateAddress"
          >
            Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="searchAddressDialog"
      persistent
      scrollable
      max-width="800px"
    >
      <v-card>
        <v-toolbar dark color="teal lighten-1" elevation="0">
          <v-toolbar-title class="">
            Buscar CEP pelo Logradouro
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formSearchAddress" v-model="valid">
              <v-row class="d-flex justify-center align-center">
                <v-col cols="2">
                  <v-text-field
                    denso
                    v-model="address.state"
                    label="UF: *"
                    required
                    >ES</v-text-field
                  >
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    denso
                    v-model="address.city"
                    label="Cidade: *"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    denso
                    v-model="address.street"
                    label="Endereço: *"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    icon
                    elevation="2"
                    small
                    @click="getAddressByPostalCode()"
                  >
                    <v-icon> mdi-magnify </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>

          <v-container>
            <v-data-table
              :headers="headerAddressByPostalCodeTb"
              :items="listAddressByPostalCode"
              :items-per-page="5"
              class="elevation-1"
              :footer-props="{ 'items-per-page-text': 'Registros por página' }"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  elevation="1"
                  small
                  @click="
                    selectAddress(item);
                    searchAddressDialog = false;
                  "
                >
                  Utilizar
                </v-btn>
              </template>
              <template slot="no-data">
                Nenhuma informação há exibir.
              </template>
              <template slot="no-results">
                Nenhuma informação há exibir.
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            color="grey"
            small
            outlined
            @click="searchAddressDialog = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style></style>
<script>
import swal from 'sweetalert2';
import api from '../plugins/Api';
import moment from 'moment';

export default {
  name: 'DadosEndereco',
  props: {},
  filters: {
    uppercase: function (text) {
      return text ? text.toUpperCase() : text;
    },
    dateFormat: function (date) {
      return date
        ? moment(date, 'YYYY-MM-DD T00:00:00').format('DD/MM/YYYY')
        : date;
    },
  },
  data: () => ({
    isLoading: false,
    dialog: false,
    searchAddressDialog: false,
    modalTitle: '',
    modalType: '',
    modalBtnCancel: 'Cancelar',
    valid: true,
    listAddress: [],
    listAddressByPostalCode: [],
    address: {
      id: '',
      cep: '',
      street: '',
      number: '',
      comp: '',
      district: '',
      city: '',
      state: '',
      listId: '',
      instNumber: '',
      apelido: '',
    },
    TableProps: {
      props: {
        sortByText: 'Ordenar por:',
      },
      header: [
        {
          text: 'Apelido',
          sortable: true,
          value: 'address_apelido',
        },
        {
          text: 'CEP',
          sortable: true,
          value: 'address_cep',
        },
        {
          text: 'Logradouro',
          sortable: true,
          value: 'address_street',
        },
        {
          text: 'Bairro',
          sortable: true,
          value: 'address_district',
        },
        {
          text: 'Cidade',
          sortable: true,
          value: 'address_city',
        },
        {
          text: 'UF',
          value: 'address_state',
        },
        {
          text: 'Opções',
          value: 'actions',
          sortable: false,
        },
      ],
      footer: {
        'items-per-page-text': 'Registros por página',
        'items-per-page-options': [10, 20, 30, 40, 50],
        pageText: '{0}-{1} de {2}',
      },
    },
    headerAddressByPostalCodeTb: [
      {
        text: 'CEP',
        sortable: true,
        value: 'cep',
      },
      {
        text: 'Logradouro',
        sortable: true,
        value: 'logradouro',
      },
      {
        text: 'Bairro',
        sortable: true,
        value: 'bairro',
      },
      {
        text: 'Cidade',
        sortable: true,
        value: 'localidade',
      },
      {
        text: 'UF',
        value: 'uf',
      },
      {
        text: 'Opções',
        value: 'actions',
        sortable: false,
      },
    ],
  }),
  methods: {
    reset() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
    async getAddress() {
      this.isLoading = true;
      let vm = this;
      let clientId = this.$route.params.id;

      api
        .get(`/clients/${clientId}/address`)
        .then(function (response) {
          if (response.status === 200) {
            vm.listAddress = response.data;
          }
          vm.isLoading = false;
        })
        .catch(function (err) {
          swal
            .mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 5000,
            })
            .fire({
              icon: 'error',
              title: err.response.data.message,
            });
          vm.isLoading = false;
        });
    },
    async insertAddress() {
      let vm = this;
      if (this.$refs.form.validate()) {
        const params = new URLSearchParams();
        params.append('clientId', vm.$route.params.id);
        params.append('apelido', vm.address.apelido);
        params.append('cep', vm.address.cep);
        params.append('street', vm.address.street);
        params.append('number', vm.address.number);
        params.append('comp', vm.address.comp);
        params.append('district', vm.address.district);
        params.append('city', vm.address.city);
        params.append('state', vm.address.state);
        params.append('instNumber', vm.address.instNumber);

        await api
          .post('/address', params)
          .then(function (response) {
            if (response.status === 201) {
              vm.getAddress();
              swal.fire(
                'Cadastrado!',
                'Os dados foram cadastrados com sucesso.',
                'success'
              );
              vm.reset();
            } else {
              swal
                .mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 5000,
                })
                .fire({
                  icon: 'error',
                  title: response.data.message,
                });
            }
          })
          .catch(function (err) {
            swal
              .mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 5000,
              })
              .fire({
                icon: 'error',
                title: err.response.data.message,
              });
          });
      }
    },
    async updateAddress() {
      let vm = this;
      swal
        .fire({
          title: 'Deseja atualizar este registro?',
          text: 'Atualizar Endereço.',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })
        .then(async function (result) {
          if (result.value) {
            let id = vm.address.id;
            const params = new URLSearchParams();
            params.append('apelido', vm.address.apelido);
            params.append('cep', vm.address.cep);
            params.append('street', vm.address.street);
            params.append('number', vm.address.number);
            params.append('comp', vm.address.comp);
            params.append('district', vm.address.district);
            params.append('city', vm.address.city);
            params.append('state', vm.address.state);
            params.append('instNumber', vm.address.instNumber);

            await api
              .put(`/address/${id}`, params)
              .then(function (response) {
                if (response.status === 204) {
                  swal.fire(
                    'Atualizado!',
                    'Os dados foram atualizados com sucesso.',
                    'success'
                  );
                  vm.getAddress();
                  vm.reset();
                } else {
                  swal.fire('Falha!', response.data.message, 'warning');
                }
              })
              .catch(function (err) {
                swal
                  .mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 5000,
                  })
                  .fire({
                    icon: 'error',
                    title: err.response.data.message,
                  });
              });
          }
        });
    },
    async deleteAddress(id) {
      let vm = this;
      let index = this.listAddress.indexOf(id);
      swal
        .fire({
          title: 'Deseja excluir este registro?',
          text: 'Esta ação é irreversivel.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })
        .then(async function (result) {
          if (result.value) {
            let id = vm.listAddress[index].address_id;

            await api
              .delete(`/address/${id}`)
              .then(function (response) {
                if (response.status === 200) {
                  vm.listAddress.splice(index, 1);
                  swal.fire(
                    'Apagado!',
                    'A Informação foi apagada permanentemente.',
                    'success'
                  );
                } else {
                  swal
                    .mixin({
                      toast: true,
                      position: 'bottom-end',
                      showConfirmButton: false,
                      timer: 5000,
                    })
                    .fire({
                      icon: 'error',
                      title: response.data.message,
                    });
                }
              })
              .catch(function (err) {
                swal
                  .mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 5000,
                  })
                  .fire({
                    icon: 'error',
                    title: err.response.data.message,
                  });
              });
          }
        });
    },
    viewModalAddress(type, id = 'null') {
      if (type === 'insert') {
        this.modalTitle = 'Cadastrar Endereço';
      }
      if (type === 'update') {
        this.modalTitle = 'Editar Endereço';
        id = this.listAddress.indexOf(id);
        this.address.id = this.listAddress[id].address_id;
        this.address.apelido = this.listAddress[id].address_apelido;
        this.address.cep = this.listAddress[id].address_cep;
        this.address.street = this.listAddress[id].address_street;
        this.address.number = this.listAddress[id].address_number;
        this.address.comp = this.listAddress[id].address_comp;
        this.address.instNumber = this.listAddress[id].address_codInstalation;
        this.address.district = this.listAddress[id].address_district;
        this.address.city = this.listAddress[id].address_city;
        this.address.state = this.listAddress[id].address_state;
        this.address.listId = id;
      }
      this.modalType = type;
      this.dialog = true;
    },
    async getPostalCodeData() {
      let vm = this;
      var urlAPI =
        'https://viacep.com.br/ws/' +
        vm.address.cep.replace(/\D/, '') +
        '/json/';
      if (vm.address.cep.length == 9) {
        await api.get(String(urlAPI)).then(function (response) {
          if (response.status == 200) {
            vm.address.street = response.data.logradouro;
            vm.address.district = response.data.bairro;
            vm.address.city = response.data.localidade;
            vm.address.state = response.data.uf;
          }
        });
      }
    },
    async getAddressByPostalCode() {
      let vm = this;
      var urlAPI = `https://viacep.com.br/ws/${vm.address.state}/${vm.address.city}/${vm.address.street}/json/`;
      if (
        vm.address.state != '' &&
        vm.address.city != '' &&
        vm.address.street != ''
      ) {
        await api
          .get(String(urlAPI))
          .then(function (response) {
            if (response.status == 200) {
              if (response.data.length > 0) {
                vm.listAddressByPostalCode = response.data;
              } else {
                swal
                  .mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 5000,
                  })
                  .fire({
                    icon: 'error',
                    title: 'Endereço não encontrado!',
                  });
              }
            }
          })
          .catch(function (error) {
            console.log(error.data);
            swal
              .mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 5000,
              })
              .fire({
                icon: 'error',
                title: 'Não foi possível realizar a busca. Tente novamente.',
              });
          });
      } else {
        swal
          .mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 5000,
          })
          .fire({
            icon: 'error',
            title: 'Favor informar UF, Cidade e Endereço.',
          });
      }
    },
    selectAddress(address) {
      this.address.cep = address.cep;
      this.address.street = address.logradouro;
      this.address.district = address.bairro;
      this.address.city = address.localidade;
      this.address.state = address.uf;
    },
  },
  created: function () {
    this.getAddress();
  },
};
</script>
