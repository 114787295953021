import jwtDecode from 'jwt-decode'
import api from '../plugins/Api'
// import router from '../router'

export const authMsg = {
    message: '',
    setMsg(msg) {
        this.message = msg
    }
}

export async function signIn(user, pass) {
    const body = new URLSearchParams();
    body.append('user', user)
    body.append('pass', pass)

    await api.post('/auth', body).then(function (response) {
        if (response.data.auth) {
            setToken(response.data.token)
            return true
        } else {
            authMsg.setMsg(response.data.message)
            return false
        }
    })
}

export function signOut() {
    localStorage.removeItem("token");
}

export function isSignedIn() {
    const token = getToken() || false;

    if (!token) {
        return false
    }
    if (token == '') {
        return false
    }
    const { exp: expiration } = jwtDecode(token)
    if (expiration * 1000 < Date.now()) {
        return false
    }
    return true
}

export function getName() {
    if (isSignedIn()) {
        const { name: login_name } = jwtDecode(getToken())
        return login_name
    }
    return false
}

export function setToken(token){
    localStorage.setItem('token',token)
}

export function getToken(){
    return localStorage.getItem('token');
}