
<template>
  <div>
    <v-main>
      <v-container fluid>
        <v-card elevation="2">
          <v-toolbar dark color="teal darken-2" elevation="0" dense>
            <v-btn
              color="teal"
              rounded
              small
              class="white--text pull-left"
              @click="goBack"
            >
              <v-icon left>mdi-arrow-left</v-icon>
              Voltar
            </v-btn>
            <v-toolbar-title class="caption">
              Ficha Pessoa Física
            </v-toolbar-title>
          </v-toolbar>
          <v-tabs v-model="tabs">
            <v-tab key="'pes'" class="caption">Dados Pessoais</v-tab>
            <!-- <v-tab key="'dep'" class="caption">Dependêntes</v-tab> -->
            <v-tab key="'fin'" class="caption" disabled>Financeiro</v-tab>
            <v-tab key="'con'" class="caption">Contrato</v-tab>
            <!-- <v-tab key="'pos'" class="caption" disabled>Pós-Venda</v-tab> -->
            <v-tab key="'obs'" class="caption">Observações</v-tab>
            <!-- <v-tab key="'his'" class="caption">Histórico</v-tab> -->
            <v-tab key="'adj'" class="caption" disabled>Ajustes</v-tab>

            <v-tabs-items v-model="tabs">
              <v-tab-item key="'pes'">
                <DetailClient />
              </v-tab-item>
              <!-- <v-tab-item key="'dep'">
                <DetailDependents/>
              </v-tab-item> -->
              <v-tab-item key="'fin'">
                <div class="tab-pane">Financeiro</div>
              </v-tab-item>
              <v-tab-item key="'con'">
                <DadosContrato/>
              </v-tab-item>
              <!-- <v-tab-item key="'pos'">
                <div class="tab-pane">posvenda</div>
              </v-tab-item> -->
              <v-tab-item key="'obs'">
                <div class="tab-pane">
                  <DetailObservation />
                </div>
              </v-tab-item>
              <!-- <v-tab-item key="'his'">
                <DetailHistory />
              </v-tab-item> -->
              <v-tab-item key="'adj'">
                <div class="tab-pane"></div>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-card>
      </v-container>
    </v-main>

    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<style lang="scss">

</style>

<script>
import DetailClient from "../components/DetailClient.vue";
import DetailObservation from "../components/DetailObservation.vue";
// import DetailHistory from "../components/DetailHistory.vue";
// import DetailDependents from "../components/DetailDependents.vue"
import DadosContrato from "../components/DetailContract.vue"

export default {
  components: {
    DetailClient,
    DetailObservation,
    // DetailHistory,
    // DetailDependents,
    DadosContrato
  },
  data: () => ({
    isLoading: true,
    drawer: null,
    search: "",
    tabs: ""
  }),
  methods: {
    goBack() {
        this.$router.go(-1)
      }
  },
  created: function () {
  },
  mounted: function () {
    this.isLoading = false;
  },
};
</script>