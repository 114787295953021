<template>
  <v-card>
    <v-card-text>
      <v-card :loading="isLoading" outlined tile>
        <v-toolbar dark color="grey" elevation="0" dense>
          <v-btn
            color="transparent"
            rounded
            small
            class=" white--text"
            @click="getObservations()"
          >
            <v-icon small> mdi-refresh </v-icon>
          </v-btn>
          <v-toolbar-title>&nbsp; </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-2"
            right
            rounded
            small
            class="sm-2 white--text"
            @click="viewModalObservation('insert')"
          >
            <v-icon small left> mdi-plus-circle </v-icon>
            Nova Observação
          </v-btn>
        </v-toolbar>
        <v-card-text class="">
          <v-data-table
            :headers="TableProps.header"
            :header-props="TableProps.props"
            :footer-props="TableProps.footer"
            :items="listObservations"
            class="elevation-1"
          >
            <template v-slot:[`item.observation_dateOccurred`]="{ item }">
              {{ item.observation_dateOccurred | dateFormat }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="viewModalObservation('update', item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteObservation(item)">
                mdi-delete
              </v-icon>
            </template>
            <template slot="no-data"> Nenhuma informação há exibir. </template>
            <template slot="no-results">
              Nenhuma informação há exibir.
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
      <v-card>
        <v-toolbar dark color="teal lighten-1" elevation="0">
          <v-toolbar-title class="">
            {{ modalTitle }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    type="date"
                    v-model="observation.dateOccurred"
                    label="Data: *"
                    required
                    :rules="[v => !!v || 'Campo obrigatório']"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="observation.title"
                    label="Título: *"
                    :rules="[v => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="observation.description"
                    label="Descrição: *"
                    required
                    :rules="[v => !!v || 'Campo obrigatório']"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*Campos obrigatórios</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="grey" small outlined reset @click="reset">
            {{ modalBtnCancel }}
          </v-btn>
          <v-btn
            dark
            rounded
            v-if="modalType == 'insert'"
            color="teal lighten-1"
            submit
            @click="insertObservation()"
          >
            Cadastrar
          </v-btn>
          <v-btn
            dark
            rounded
            v-else-if="modalType == 'update'"
            color="teal lighten-1"
            submit
            @click="updateObservation()"
          >
            Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<style></style>
<script>
import swal from 'sweetalert2';
import api from '../plugins/Api';
import moment from 'moment';

export default {
  name: 'DetailObservation',
  props: {},
  filters: {
    uppercase: function(text) {
      return text.toUpperCase();
    },
    dateFormat: function(date) {
      return date
        ? moment(date, 'YYYY-MM-DD T00:00:00').format('DD/MM/YYYY')
        : date;
    }
  },
  data: () => ({
    isLoading: false,
    dialog: false,
    modalTitle: '',
    modalType: '',
    modalBtnCancel: 'Cancelar',
    valid: true,
    listObservations: [],
    observation: {
      title: '',
      description: '',
      dateOccurred: '',
      listId: ''
    },
    TableProps: {
      props: {
        sortByText: 'Ordenar por:'
      },
      header: [
        {
          text: 'Data',
          sortable: true,
          value: 'observation_dateOccurred'
        },
        {
          text: 'Título',
          value: 'observation_title'
        },
        {
          text: 'Descrição',
          value: 'observation_description'
        },
        {
          text: 'Opções',
          value: 'actions',
          sortable: false
        }
      ],
      footer: {
        'items-per-page-text': 'Registros por página',
        'items-per-page-options': [10, 20, 30, 40, 50],
        pageText: '{0}-{1} de {2}'
        // 'disable-items-per-page': false
      }
    }
  }),
  methods: {
    reset() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
    async getObservations() {
      this.isLoading = true;
      let vm = this;
      let clientId = this.$route.params.id;

      api
        .get(`/clients/${clientId}/observations`)
        .then(function(response) {
          if (response.status === 200) {
            vm.listObservations = response.data;
          }
          vm.isLoading = false;
        })
        .catch(function(error) {
          swal
            .mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 5000
            })
            .fire({
              icon: 'error',
              title: error.response.data.message
            });
            vm.isLoading = false;
        });
        
    },
    async insertObservation() {
      let vm = this;
      if (this.$refs.form.validate()) {
        const params = new URLSearchParams();
        params.append('clientId', this.$route.params.id);
        params.append('title', vm.observation.title);
        params.append('description', vm.observation.description);
        params.append('dateOccurred', vm.observation.dateOccurred);

        await api
          .post('/observations', params)
          .then(function(response) {
            if (response.status === 201) {
              vm.getObservations();
              vm.reset();
              swal
                .mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 5000
                })
                .fire({
                  icon: 'success',
                  title: response.data.message
                });
            } else {
              swal
                .mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 5000
                })
                .fire({
                  icon: 'error',
                  title: response.data.message
                });
            }
          })
          .catch(function(error) {
            swal
              .mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 5000
              })
              .fire({
                icon: 'error',
                title: error.response.data.message
              });
          });
      }
    },
    async updateObservation() {
      let vm = this;
      swal
        .fire({
          title: 'Deseja atualizar este registro?',
          text: 'Atualizar dados do contato.',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        })
        .then(async function(result) {
          if (result.value) {
            let id = vm.listObservations[vm.observation.listId].observation_id;

            const params = new URLSearchParams();

            params.append('title', vm.observation.title);
            params.append('description', vm.observation.description);
            params.append('dateOccurred', vm.observation.dateOccurred);

            await api
              .put(`/observations/${id}`, params)
              .then(function(response) {
                if (response.status === 204) {
                  swal.fire(
                    'Atualizado!',
                    'Os dados foram atualizados com sucesso.',
                    'success'
                  );
                  vm.getObservations();
                  vm.reset();
                } else {
                  swal.fire('Falha!', response.data.message, 'warning');
                }
              })
              .catch(function(err) {
                swal
                  .mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 5000
                  })
                  .fire({
                    icon: 'error',
                    title: err.response.data.message
                  });
              });
          }
        });
    },
    async deleteObservation(id) {
      let vm = this;
      let index = this.listObservations.indexOf(id);
      swal
        .fire({
          title: 'Deseja excluir este registro?',
          text: 'Esta ação é irreversivel.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        })
        .then(async function(result) {
          if (result.value) {
            let id = vm.listObservations[index].observation_id;
            await api
              .delete(`/observations/${id}`)
              .then(function(response) {
                if (response.status === 200) {
                  vm.listObservations.splice(index, 1);
                  swal.fire(
                    'Apagado!',
                    'A Informação foi apagada permanentemente.',
                    'success'
                  );
                } else {
                  swal
                    .mixin({
                      toast: true,
                      position: 'bottom-end',
                      showConfirmButton: false,
                      timer: 5000
                    })
                    .fire({
                      icon: 'error',
                      title: response.data.message
                    });
                }
              })
              .catch(function(err) {
                swal
                  .mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 5000
                  })
                  .fire({
                    icon: 'error',
                    title: err.response.data.message
                  });
              });
          }
        });
    },
    viewModalObservation(type, id = 'null') {
      this.modalBtnCancel = 'Cancelar';
      if (type === 'insert') {
        this.modalTitle = 'Cadastrar Observação';
      }
      if (type === 'update') {
        let index = this.listObservations.indexOf(id);
        const {
          observation_title,
          observation_description,
          observation_dateOccurred
        } = this.listObservations[index];
        this.modalTitle = 'Editar Observação';
        this.observation.title = observation_title;
        this.observation.description = observation_description;
        this.observation.dateOccurred = moment(
          observation_dateOccurred,
          'YYYY-MM-DD T00:00:00'
        ).format('YYYY-MM-DD');
        this.observation.listId = index;
      }
      this.modalType = type;

      this.dialog = true;
    }
  },
  created: function() {
    this.getObservations();
  }
};
</script>
