<template>
  <v-card elevation="1" outlined tile height="100%">
    <v-toolbar dark color="grey" elevation="0" dense>
      <v-toolbar-title class="text-left"> Dados </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="grey darken-2" right rounded small class="sm-2 white--text">
        <v-icon small left>mdi-pencil</v-icon>
        Editar
      </v-btn>
    </v-toolbar>
    <v-card-text class="">
      <v-list dense>
        <v-list-item>
          <v-list-item-content class="text-left">
            <v-list-item-title
              >Nome:&nbsp;<span class="caption">{{
                client.client_name | uppercase
              }}</span></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="client.client_type=='pf'">
          <v-list-item-content class="text-left">
            <v-list-item-title
              >CPF:&nbsp;<span class="caption">{{
                client.client_cpfCnpj| VMask("###.###.###-##")
              }}</span></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="client.client_type=='pj'">
          <v-list-item-content class="text-left">
            <v-list-item-title
              >CNPJ:&nbsp;<span class="caption">{{
                client.client_cpfCnpj| VMask("##.###.###/####-##")
              }}</span></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item>
          <v-list-item-content class="text-left">
            <v-list-item-title
              >Data de Nascimento:&nbsp;<span class="caption">{{
                client.client_birthDate | dateFormat
              }}</span></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="text-left">
            <v-list-item-title
              >CIC/RG:&nbsp;<span class="caption">{{
                client.client_cic
              }}</span></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="text-left">
            <v-list-item-title>
              Sexo:&nbsp;
              <template>
                <span class="caption" v-if="client.client_sex === 'M'">
                  {{ "Masculino" }}
                </span>
                <span class="caption" v-else-if="client.client_sex === 'F'">
                  {{ "Feminino" }}
                </span>
              </template>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item>
          <v-list-item-content class="text-left">
            <v-list-item-title
              >Representante:&nbsp;<span class="caption">{{
                client.client_legalRepresentative | uppercase
              }}</span></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="text-left">
            <v-list-item-title
              >CPF Rep.:&nbsp;<span class="caption">{{
                client.client_legalRepresentativeCPF| VMask("###.###.###-##")
              }}</span></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<style>
</style>
<script>
import swal from "sweetalert2";
import api from "../plugins/Api";
import moment from "moment";

export default {
  name: "DadosCliente",
  props:{
  },
  filters: {
    uppercase: function (text) {
      return text ? text.toUpperCase() : text;
    },
    dateFormat: function (date) {
      return date
        ? moment(date, "YYYY-MM-DD T00:00:00").format("DD/MM/YYYY")
        : date;
    },
  },
  data: () => ({
    urlBase: '/clients',
    isLoading:false,
    client: {},
  }),
  methods: {
    async getClient() {
      let vm = this;
      this.isLoading = true;
      await api
        .get(`${vm.urlBase}/${this.$route.params.id}`)
        .then(function (response) {
          if (response.status==200) {
            vm.client = response.data;
          } else {
            swal
              .mixin({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 5000,
              })
              .fire({
                icon: "error",
                title: response.data.message,
              });
          }
        })
        .catch(function () {
          swal
            .mixin({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 5000,
            })
            .fire({
              icon: "error",
              title: "Erro na solicitação",
            });
        });
      this.isLoading = false;
    },
  },
  created: function () {
    this.getClient();
  },
};
</script>
