var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-toolbar',{attrs:{"dark":"","color":"teal darken-2","elevation":"0"}},[_c('v-toolbar-title',{staticClass:"caption"},[_vm._v("Serviços")])],1),_c('v-card-title',[_c('v-btn',{staticClass:"sm-2 white--text",attrs:{"color":"teal lighten-1","rounded":"","small":""},on:{"click":function($event){return _vm.viewModal()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus-circle")]),_vm._v(" Novo Serviço ")],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerProps,"items":_vm.listServices,"search":_vm.search,"footer-props":_vm.footerProps,"loading":_vm.isLoading,"loading-text":"Carregando..."},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uppercase")(item.nome))+" ")]}},{key:"item.contractType",fn:function(ref){
var item = ref.item;
return [(item.contractType == 'COL')?_c('div',[_vm._v("Coleta")]):(item.contractType == 'ANA')?_c('div',[_vm._v("Análise")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"sm-2 white--text",attrs:{"color":"red accent-3","rounded":"","x-small":""},on:{"click":function($event){return _vm.viewModal('update', item)}}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Editar ")],1)]}}],null,true)},[_c('template',{slot:"no-data"},[_vm._v(" Nenhuma informação há exibir. ")]),_c('template',{slot:"no-results"},[_vm._v(" Nenhuma informação há exibir. ")])],2)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"teal lighten-1","elevation":"0"}},[_c('v-toolbar-title',{},[_vm._v(" "+_vm._s(_vm.modalTitle)+" ")])],1),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nome do serviço: *","rules":[function (v) { return !!v || 'Campo obrigatório'; }],"required":""},model:{value:(_vm.service.nome),callback:function ($$v) {_vm.$set(_vm.service, "nome", $$v)},expression:"service.nome"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":[
                    { id: 'COL', name: 'Coleta' },
                    { id: 'ANA', name: 'Analise' }
                  ],"label":"Tipo de Contrato: *","required":"","rules":[function (v) { return !!v || 'Campo obrigatório'; }],"item-value":"id","item-text":"name"},model:{value:(_vm.service.contractType),callback:function ($$v) {_vm.$set(_vm.service, "contractType", $$v)},expression:"service.contractType"}})],1)],1)],1)],1),_c('small',[_vm._v("*Campos obrigatórios")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","color":"grey","small":"","outlined":"","reset":""},on:{"click":_vm.reset}},[_vm._v(" "+_vm._s(_vm.modalBtnCancel)+" ")]),(_vm.modalType == 'insert')?_c('v-btn',{attrs:{"dark":"","rounded":"","color":"teal lighten-1","submit":""},on:{"click":function($event){return _vm.insertService()}}},[_vm._v(" Cadastrar ")]):(_vm.modalType == 'update')?_c('v-btn',{attrs:{"dark":"","rounded":"","color":"teal lighten-1","submit":""},on:{"click":function($event){return _vm.updateService()}}},[_vm._v(" Atualizar ")]):_vm._e()],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }