<template>
  <div>
    <v-main>
      <v-container fluid>
        <v-card elevation="2">
          <v-toolbar dark color="teal darken-2" elevation="0">
            <v-toolbar-title class="caption">Motoristas</v-toolbar-title>
          </v-toolbar>
          <v-card-title>
            <v-btn
              color="teal lighten-1"
              rounded
              small
              class="sm-2 white--text"
              @click="viewModal()"
            >
              <v-icon small left>mdi-plus-circle</v-icon>
              Novo Motorista
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headerProps"
            :items="listDrivers"
            :search="search"
            class="elevation-1"
            :footer-props="footerProps"
            :loading="isLoading"
            loading-text="Carregando..."
          >
            <template v-slot:[`item.nome`]="{ item }">
              {{ item.nome | uppercase }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <!-- <v-btn color="primary" rounded x-small class="sm-2 white--text" :to="'/cliente/pj/detalhe/' + item.client_id">
                <v-icon x-small left>mdi-eye</v-icon>
                Visualizar
              </v-btn> -->
              <v-btn
                color="red accent-3"
                rounded
                x-small
                class="sm-2 white--text"
                @click="viewModal('update', item)"
              >
                <v-icon x-small left> mdi-pencil </v-icon>
                Editar
              </v-btn>
            </template>
            <template slot="no-data"> Nenhuma informação há exibir. </template>
            <template slot="no-results">
              Nenhuma informação há exibir.
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </v-main>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
      <v-card>
        <v-toolbar dark color="teal lighten-1" elevation="0">
          <v-toolbar-title class="">
            {{ modalTitle }}
          </v-toolbar-title>
        </v-toolbar>
        <!-- <v-card-title>
          <span class="headline">{{ modalTitle }}</span>
        </v-card-title> -->
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="driver.nome"
                    label="Nome do motorista: *"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*Campos obrigatórios</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="grey" small outlined reset @click="reset">
            {{ modalBtnCancel }}
          </v-btn>
          <v-btn
            dark
            rounded
            v-if="modalType == 'insert'"
            color="teal lighten-1"
            submit
            @click="insertDriver()"
          >
            Cadastrar
          </v-btn>
          <v-btn
            dark
            rounded
            v-else-if="modalType == 'update'"
            color="teal lighten-1"
            submit
            @click="updateDriver()"
          >
            Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import api from '../plugins/Api';
import swal from 'sweetalert2';

export default {
  filters: {
    uppercase: function (text) {
      return text.toUpperCase();
    },
    dateFormat: function (date) {
      return new Date(date).toLocaleDateString('pt-BR');
    },
  },
  data: () => ({
    urlBase: '/drivers',
    isLoading: true,
    overlay: true,
    drawer: null,
    dialog: false,
    modalTitle: '',
    modalType: '',
    modalBtnCancel: 'Cancelar',
    valid: true,
    search: '',
    headerProps: [
      {
        text: 'Nome',
        sortable: true,
        value: 'nome',
      },
      {
        text: 'Opções',
        value: 'actions',
        sortable: false,
        align: 'end',
      },
    ],
    footerProps: {
      'items-per-page-text': 'Registros por página',
      'items-per-page-options': [10, 20, 30, 40, 50],
      pageText: '{0}-{1} de {2}',
      // 'disable-items-per-page': false
    },
    listDrivers: [],
    driver: {
      id: '',
      nome: '',
    },
  }),
  methods: {
    toggleOverlay() {
      this.overlay = !this.overlay;
    },
    reset() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    async getDrivers() {
      let vm = this;
      this.isLoading = true;
      await api
        .get(vm.urlBase)
        .then(function (response) {
          if (response.status == 200) {
            vm.listDrivers = response.data;
          } else {
            swal
              .mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 5000,
              })
              .fire({
                icon: 'error',
                title: response.data.message,
              });
          }
        })
        .catch(function () {
          swal
            .mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 5000,
            })
            .fire({
              icon: 'error',
              title: 'Erro na solicitacao',
            });
        });
      this.isLoading = false;
    },
    async insertDriver() {
      if (this.$refs.form.validate()) {
        let vm = this;
        this.isLoading = true;
        const params = new URLSearchParams();
        params.append('nome', this.driver.nome);
        await api
          .post(vm.urlBase, params)
          .then(function (response) {
            if (response.status == 201) {
              swal
                .mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 5000,
                })
                .fire({
                  icon: 'success',
                  title: 'Serviço cadastrado com sucesso!',
                });
              vm.getDrivers();
              vm.reset();
            } else {
              swal
                .mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 5000,
                })
                .fire({
                  icon: 'error',
                  title: response.data.message,
                });
            }
          })
          .catch(function () {
            swal
              .mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 5000,
              })
              .fire({
                icon: 'error',
                title: 'Erro Inesperado.',
              });
          });
      }
      this.isLoading = false;
    },
    updateDriver() {
      this.isLoading = true;
      let vm = this;
      swal
        .fire({
          title: 'Deseja atualizar este registro?',
          text: 'Não é possível desfazer esta ação.',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })
        .then(async (result) => {
          if (result.value) {
            const params = new URLSearchParams();
            params.append('id', vm.driver.id);
            params.append('nome', vm.driver.nome);

            await api
              .put(`${vm.urlBase}/${vm.driver.id}`, params)
              .then(function (response) {
                if (response.status == 204) {
                  swal.fire(
                    'Atualizado!',
                    'Registro atualizado com sucesso!',
                    'success'
                  );
                  vm.getDrivers();
                  vm.modalBtnCancel = 'Sair';
                  vm.isLoading = false;
                } else {
                  swal.fire('Falha!', response.data.message, 'warning');
                }
              })
              .catch(function (err) {
                swal
                  .mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 5000,
                  })
                  .fire({
                    icon: 'error',
                    title: err.message,
                  });
              });
          }
        });
      this.isLoading = false;
    },
    viewModal(type = 'insert', driver = 'null') {
      this.modalBtnCancel = 'Cancelar';
      if (type === 'update') {
        this.modalTitle = 'Editar Motorista';
        this.driver.id = driver.id;
        this.driver.nome = driver.nome;
      } else {
        this.modalTitle = 'Cadastrar Motorista';
      }

      this.modalType = type;
      this.dialog = true;
    },
  },
  created: function () {},
  mounted: function () {
    this.getDrivers();
    this.toggleOverlay();
  },
};
</script>
