var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"grey","elevation":"0","dense":""}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"transparent","rounded":"","small":""},on:{"click":function($event){return _vm.list()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-refresh ")])],1),_c('v-toolbar-title',[_vm._v("  ")]),_c('v-spacer'),_c('v-btn',{staticClass:"sm-2 white--text",attrs:{"color":"grey darken-2","right":"","rounded":"","small":""},on:{"click":function($event){return _vm.viewModal('insert')}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Novo Contrato ")],1)],1),_c('v-card-text',{},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.TableProps.header,"header-props":_vm.TableProps.props,"footer-props":_vm.TableProps.footer,"items":_vm.listContract,"loading":_vm.isLoading,"loading-text":"Carregando..."},scopedSlots:_vm._u([{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uppercase")(item.tipo == 'COL' ? 'Coleta' : 'Análise'))+" ")]}},{key:"item.assinatura",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.assinatura))+" ")]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uppercase")(item.ativo ? 'Ativo' : 'Cancelado'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewModal('update', item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('template',{slot:"no-data"},[_vm._v(" Nenhuma informação há exibir. ")]),_c('template',{slot:"no-results"},[_vm._v(" Nenhuma informação há exibir. ")])],2)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"teal lighten-1","elevation":"0"}},[_c('v-toolbar-title',{},[_vm._v(" "+_vm._s(_vm.modalTitle)+" ")])],1),_c('v-card-text',{staticStyle:{"height":"500px"}},[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Numero do Contrato: *","rules":[function (v) { return !!v || 'Campo obrigatório'; }],"dense":"","required":""},model:{value:(_vm.contract.numero),callback:function ($$v) {_vm.$set(_vm.contract, "numero", $$v)},expression:"contract.numero"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":[
                    { id: 'COL', name: 'Coleta' },
                    { id: 'ANA', name: 'Análise' } ],"label":"Tipo de Contrato: *","required":"","rules":[function (v) { return !!v || 'Campo obrigatório'; }],"dense":"","item-value":"id","item-text":"name"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uppercase")(item.name))+" ")]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uppercase")(item.name))+" ")]}}]),model:{value:(_vm.contract.tipo),callback:function ($$v) {_vm.$set(_vm.contract, "tipo", $$v)},expression:"contract.tipo"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":[
                    { id: 'bol', name: 'Boleto Bancario' },
                    { id: 'din', name: 'Dinheiro' },
                    { id: 'ted', name: 'TED' },
                    { id: 'pix', name: 'PIX' } ],"label":"Forma de Pagamento: *","required":"","rules":[function (v) { return !!v || 'Campo obrigatório'; }],"dense":"","item-value":"id","item-text":"name"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uppercase")(item.name))+" ")]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uppercase")(item.name))+" ")]}}]),model:{value:(_vm.contract.forma_pagamento),callback:function ($$v) {_vm.$set(_vm.contract, "forma_pagamento", $$v)},expression:"contract.forma_pagamento"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"label":"Valor do Contrato: *","dense":"","required":"","rules":[function (v) { return !!v || 'Campo obrigatório'; }]},model:{value:(_vm.contract.taxa),callback:function ($$v) {_vm.$set(_vm.contract, "taxa", $$v)},expression:"contract.taxa"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"label":"Valor Kg Excedente: ","dense":""},model:{value:(_vm.contract.valor_kg_exced),callback:function ($$v) {_vm.$set(_vm.contract, "valor_kg_exced", $$v)},expression:"contract.valor_kg_exced"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"label":"Crédito (Kg)","color":"secondary","hide-details":"","dense":""},model:{value:(_vm.contract.possui_credito),callback:function ($$v) {_vm.$set(_vm.contract, "possui_credito", $$v)},expression:"contract.possui_credito"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Quantidade: ","dense":"","disabled":!_vm.contract.possui_credito},model:{value:(_vm.contract.credito),callback:function ($$v) {_vm.$set(_vm.contract, "credito", $$v)},expression:"contract.credito"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Qtd Coleta Mês: ","dense":""},model:{value:(_vm.contract.qtd_coleta_mes),callback:function ($$v) {_vm.$set(_vm.contract, "qtd_coleta_mes", $$v)},expression:"contract.qtd_coleta_mes"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":[
                    { id: 1, name: '1' },
                    { id: 2, name: '2' },
                    { id: 3, name: '3' },
                    { id: 4, name: '4' },
                    { id: 5, name: '5' },
                    { id: 6, name: '6' },
                    { id: 7, name: '7' },
                    { id: 8, name: '8' },
                    { id: 9, name: '9' },
                    { id: 10, name: '10' },
                    { id: 11, name: '11' },
                    { id: 12, name: '12' },
                    { id: 13, name: '13' },
                    { id: 14, name: '14' },
                    { id: 15, name: '15' },
                    { id: 16, name: '16' },
                    { id: 17, name: '17' },
                    { id: 18, name: '18' },
                    { id: 19, name: '19' },
                    { id: 20, name: '20' },
                    { id: 21, name: '21' },
                    { id: 22, name: '22' },
                    { id: 23, name: '23' },
                    { id: 24, name: '24' },
                    { id: 25, name: '25' },
                    { id: 26, name: '26' },
                    { id: 27, name: '27' },
                    { id: 28, name: '28' } ],"label":"Vencimento da Fatura: *","required":"","rules":[function (v) { return !!v || 'Campo obrigatório'; }],"dense":"","item-value":"id","item-text":"name"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uppercase")(item.name))+" ")]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uppercase")(item.name))+" ")]}}]),model:{value:(_vm.contract.vencimento),callback:function ($$v) {_vm.$set(_vm.contract, "vencimento", $$v)},expression:"contract.vencimento"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":[
                    { id: 1, name: '1' },
                    { id: 2, name: '2' },
                    { id: 3, name: '3' },
                    { id: 4, name: '4' },
                    { id: 5, name: '5' },
                    { id: 6, name: '6' },
                    { id: 7, name: '7' },
                    { id: 8, name: '8' },
                    { id: 9, name: '9' },
                    { id: 10, name: '10' },
                    { id: 11, name: '11' },
                    { id: 12, name: '12' },
                    { id: 13, name: '13' },
                    { id: 14, name: '14' },
                    { id: 15, name: '15' },
                    { id: 16, name: '16' },
                    { id: 17, name: '17' },
                    { id: 18, name: '18' },
                    { id: 19, name: '19' },
                    { id: 20, name: '20' },
                    { id: 21, name: '21' },
                    { id: 22, name: '22' },
                    { id: 23, name: '23' },
                    { id: 24, name: '24' },
                    { id: 25, name: '25' },
                    { id: 26, name: '26' },
                    { id: 27, name: '27' },
                    { id: 28, name: '28' } ],"label":"Dia de Faturamento: *","required":"","rules":[function (v) { return !!v || 'Campo obrigatório'; }],"dense":"","item-value":"id","item-text":"name"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uppercase")(item.name))+" ")]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uppercase")(item.name))+" ")]}}]),model:{value:(_vm.contract.faturamento_inicial),callback:function ($$v) {_vm.$set(_vm.contract, "faturamento_inicial", $$v)},expression:"contract.faturamento_inicial"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":[
                    { id: 1, name: '15 dias' },
                    { id: 2, name: '30 dias' },
                    { id: 3, name: '45 dias' },
                    { id: 4, name: '60 dias' },
                    { id: 5, name: '90 dias' } ],"label":"Período de Faturamento: *","required":"","rules":[function (v) { return !!v || 'Campo obrigatório'; }],"dense":"","item-value":"id","item-text":"name"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uppercase")(item.name))+" ")]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uppercase")(item.name))+" ")]}}]),model:{value:(_vm.contract.faturamento_periodo),callback:function ($$v) {_vm.$set(_vm.contract, "faturamento_periodo", $$v)},expression:"contract.faturamento_periodo"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"type":"date","dense":"","label":"Data de Assinatura: *","required":"","rules":[function (v) { return !!v || 'Campo obrigatório'; }]},model:{value:(_vm.contract.assinatura),callback:function ($$v) {_vm.$set(_vm.contract, "assinatura", $$v)},expression:"contract.assinatura"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"type":"date","dense":"","label":"Data de Encerramento: "},model:{value:(_vm.contract.encerramento),callback:function ($$v) {_vm.$set(_vm.contract, "encerramento", $$v)},expression:"contract.encerramento"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Observações"},model:{value:(_vm.contract.observacao),callback:function ($$v) {_vm.$set(_vm.contract, "observacao", $$v)},expression:"contract.observacao"}})],1)],1)],1)],1),_c('small',[_vm._v("*Campos obrigatórios")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","color":"grey","small":"","outlined":"","reset":""},on:{"click":_vm.reset}},[_vm._v(" "+_vm._s(_vm.modalBtnCancel)+" ")]),(_vm.modalType == 'insert')?_c('v-btn',{attrs:{"dark":"","rounded":"","color":"teal lighten-1","submit":""},on:{"click":function($event){return _vm.insert()}}},[_vm._v(" Cadastrar ")]):(_vm.modalType == 'update')?_c('v-btn',{attrs:{"dark":"","rounded":"","color":"teal lighten-1","submit":""},on:{"click":function($event){return _vm.update()}}},[_vm._v(" Atualizar ")]):_vm._e()],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }