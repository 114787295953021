<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col >
          <DadosCliente/>
        </v-col>
        <v-col >
          <DadosContato/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <DadosEndereco/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style>
</style>
<script>
import DadosCliente from '../components/DadosCliente.vue'
import DadosEndereco from '../components/DadosEndereco.vue'
import DadosContato from '../components/DadosContato.vue'

export default {
  name: "DetailClient",
  props:{
  },
  components:{
    DadosCliente,
    DadosEndereco,
    DadosContato
  },
  data: () => ({
    headerAddressByPostalCodeTb: [
      {
        text: "CEP",
        sortable: true,
        value: "cep",
      },
      {
        text: "Logradouro",
        sortable: true,
        value: "logradouro",
      },
      {
        text: "Bairro",
        sortable: true,
        value: "bairro",
      },
      {
        text: "Cidade",
        sortable: true,
        value: "localidade",
      },
      {
        text: "UF",
        value: "uf",
      },
      {
        text: "Opções",
        value: "actions",
        sortable: false,
      },
    ],
    listAddress: [],
  }),
  methods: {
    
  },
  created: function () {
    
  },
};
</script>
