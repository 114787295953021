<template>
  <v-card>
    <v-card-text>
      <v-card outlined tile>
        <v-toolbar dark color="grey" elevation="0" dense>
          <v-btn
            color="transparent"
            rounded
            small
            class="white--text"
            @click="list()"
          >
            <v-icon small> mdi-refresh </v-icon>
          </v-btn>
          <v-toolbar-title>&nbsp; </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-2"
            right
            rounded
            small
            class="sm-2 white--text"
            @click="viewModal('insert')"
          >
            <v-icon small left> mdi-plus-circle </v-icon>
            Novo Contrato
          </v-btn>
        </v-toolbar>
        <v-card-text class="">
          <v-data-table
            :headers="TableProps.header"
            :header-props="TableProps.props"
            :footer-props="TableProps.footer"
            :items="listContract"
            class="elevation-1"
            :loading="isLoading"
            loading-text="Carregando..."
          >
            <template v-slot:[`item.tipo`]="{ item }">
              {{ item.tipo == 'COL' ? 'Coleta' : 'Análise' | uppercase }}
            </template>
            <template v-slot:[`item.assinatura`]="{ item }">
              {{ item.assinatura | dateFormat }}
            </template>
            <template v-slot:[`item.ativo`]="{ item }">
              {{ item.ativo ? 'Ativo' : 'Cancelado' | uppercase }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="viewModal('update', item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="remove(item)"> mdi-delete </v-icon>
            </template>
            <template slot="no-data"> Nenhuma informação há exibir. </template>
            <template slot="no-results">
              Nenhuma informação há exibir.
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
      <v-card>
        <v-toolbar dark color="teal lighten-1" elevation="0">
          <v-toolbar-title class="">
            {{ modalTitle }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text style="height: 500px">
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="contract.numero"
                    label="Numero do Contrato: *"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="contract.tipo"
                    :items="[
                      { id: 'COL', name: 'Coleta' },
                      { id: 'ANA', name: 'Análise' },
                    ]"
                    label="Tipo de Contrato: *"
                    required
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    dense
                    item-value="id"
                    item-text="name"
                  >
                    <template slot="selection" slot-scope="{ item }">
                      {{ item.name | uppercase }}
                    </template>
                    <template slot="item" slot-scope="{ item }">
                      {{ item.name | uppercase }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-select
                    v-model="contract.forma_pagamento"
                    :items="[
                      { id: 'bol', name: 'Boleto Bancario' },
                      { id: 'din', name: 'Dinheiro' },
                      { id: 'ted', name: 'TED' },
                      { id: 'pix', name: 'PIX' },
                    ]"
                    label="Forma de Pagamento: *"
                    required
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    dense
                    item-value="id"
                    item-text="name"
                  >
                    <template slot="selection" slot-scope="{ item }">
                      {{ item.name | uppercase }}
                    </template>
                    <template slot="item" slot-scope="{ item }">
                      {{ item.name | uppercase }}
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model.lazy="contract.taxa"
                    label="Valor do Contrato: *"
                    v-money="money"
                    dense
                    required
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model.lazy="contract.valor_kg_exced"
                    label="Valor Kg Excedente: "
                    dense
                    v-money="money"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-checkbox
                    v-model="contract.possui_credito"
                    label="Crédito (Kg)"
                    color="secondary"
                    hide-details
                    dense
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="contract.credito"
                    label="Quantidade: "
                    dense
                    :disabled="!contract.possui_credito"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="contract.qtd_coleta_mes"
                    label="Qtd Coleta Mês: "
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-select
                    v-model="contract.vencimento"
                    :items="[
                      { id: 1, name: '1' },
                      { id: 2, name: '2' },
                      { id: 3, name: '3' },
                      { id: 4, name: '4' },
                      { id: 5, name: '5' },
                      { id: 6, name: '6' },
                      { id: 7, name: '7' },
                      { id: 8, name: '8' },
                      { id: 9, name: '9' },
                      { id: 10, name: '10' },
                      { id: 11, name: '11' },
                      { id: 12, name: '12' },
                      { id: 13, name: '13' },
                      { id: 14, name: '14' },
                      { id: 15, name: '15' },
                      { id: 16, name: '16' },
                      { id: 17, name: '17' },
                      { id: 18, name: '18' },
                      { id: 19, name: '19' },
                      { id: 20, name: '20' },
                      { id: 21, name: '21' },
                      { id: 22, name: '22' },
                      { id: 23, name: '23' },
                      { id: 24, name: '24' },
                      { id: 25, name: '25' },
                      { id: 26, name: '26' },
                      { id: 27, name: '27' },
                      { id: 28, name: '28' },
                    ]"
                    label="Vencimento da Fatura: *"
                    required
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    dense
                    item-value="id"
                    item-text="name"
                  >
                    <template slot="selection" slot-scope="{ item }">
                      {{ item.name | uppercase }}
                    </template>
                    <template slot="item" slot-scope="{ item }">
                      {{ item.name | uppercase }}
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="contract.faturamento_inicial"
                    :items="[
                      { id: 1, name: '1' },
                      { id: 2, name: '2' },
                      { id: 3, name: '3' },
                      { id: 4, name: '4' },
                      { id: 5, name: '5' },
                      { id: 6, name: '6' },
                      { id: 7, name: '7' },
                      { id: 8, name: '8' },
                      { id: 9, name: '9' },
                      { id: 10, name: '10' },
                      { id: 11, name: '11' },
                      { id: 12, name: '12' },
                      { id: 13, name: '13' },
                      { id: 14, name: '14' },
                      { id: 15, name: '15' },
                      { id: 16, name: '16' },
                      { id: 17, name: '17' },
                      { id: 18, name: '18' },
                      { id: 19, name: '19' },
                      { id: 20, name: '20' },
                      { id: 21, name: '21' },
                      { id: 22, name: '22' },
                      { id: 23, name: '23' },
                      { id: 24, name: '24' },
                      { id: 25, name: '25' },
                      { id: 26, name: '26' },
                      { id: 27, name: '27' },
                      { id: 28, name: '28' },
                    ]"
                    label="Dia de Faturamento: *"
                    required
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    dense
                    item-value="id"
                    item-text="name"
                  >
                    <template slot="selection" slot-scope="{ item }">
                      {{ item.name | uppercase }}
                    </template>
                    <template slot="item" slot-scope="{ item }">
                      {{ item.name | uppercase }}
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="contract.faturamento_periodo"
                    :items="[
                      { id: 1, name: '15 dias' },
                      { id: 2, name: '30 dias' },
                      { id: 3, name: '45 dias' },
                      { id: 4, name: '60 dias' },
                      { id: 5, name: '90 dias' },
                    ]"
                    label="Período de Faturamento: *"
                    required
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    dense
                    item-value="id"
                    item-text="name"
                  >
                    <template slot="selection" slot-scope="{ item }">
                      {{ item.name | uppercase }}
                    </template>
                    <template slot="item" slot-scope="{ item }">
                      {{ item.name | uppercase }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    type="date"
                    v-model="contract.assinatura"
                    dense
                    label="Data de Assinatura: *"
                    required
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    type="date"
                    v-model="contract.encerramento"
                    dense
                    label="Data de Encerramento: "
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea v-model="contract.observacao" label="Observações"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*Campos obrigatórios</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="grey" small outlined reset @click="reset">
            {{ modalBtnCancel }}
          </v-btn>
          <v-btn
            dark
            rounded
            v-if="modalType == 'insert'"
            color="teal lighten-1"
            submit
            @click="insert()"
          >
            Cadastrar
          </v-btn>
          <v-btn
            dark
            rounded
            v-else-if="modalType == 'update'"
            color="teal lighten-1"
            submit
            @click="update()"
          >
            Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>
<style></style>
<script>
import swal from 'sweetalert2';
import api from '../plugins/Api';
import moment from 'moment';
import { VMoney } from 'v-money';
import { convertNumberToUsFormat } from '../functions/helpers.js';

export default {
  name: 'DadosContrato',
  props: {},
  filters: {
    uppercase: function (text) {
      return typeof text === 'string' ? text.toUpperCase() : text;
    },
    dateFormat: function (date) {
      return date
        ? moment(date, 'YYYY-MM-DD T00:00:00').format('DD/MM/YYYY')
        : date;
    },
  },
  directives: { money: VMoney },
  data: () => ({
    urlBaseComponent: '/contracts',
    isLoading: false,
    dialog: false,
    modalTitle: '',
    modalType: '',
    modalBtnCancel: 'Cancelar',
    valid: true,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      suffix: '',
      precision: 2,
      masked: false,
    },
    contract: {
      id: '',
      forma_pagamento: '',
      tipo: '',
      numero: '',
      qtd_coleta_mes: '',
      credito: '',
      valor_kg_exced: '',
      taxa: '',
      faturamento_inicial: '',
      faturamento_periodo: '',
      vencimento: '',
      assinatura: '',
      encerramento: '',
      observacao: '',
      possui_credito: 0,
    },
    listContract: [],
    TableProps: {
      props: {
        sortByText: 'Ordenar por:',
      },
      header: [
        {
          text: 'Código',
          sortable: true,
          value: 'numero',
        },
        {
          text: 'Tipo de Contrato',
          value: 'tipo',
        },
        {
          text: 'Data de Assinatura',
          value: 'assinatura',
        },
        {
          text: 'Status',
          value: 'ativo',
        },
        {
          text: 'Opções',
          value: 'actions',
          sortable: false,
        },
      ],
      footer: {
        'items-per-page-text': 'Registros por página',
        'items-per-page-options': [10, 20, 30, 40, 50],
        pageText: '{0}-{1} de {2}',
      },
    },
  }),
  methods: {
    reset() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
    async list() {
      this.isLoading = true;
      let vm = this;
      let clientId = this.$route.params.id;

      api
        .get(`/clients/${clientId}${this.urlBaseComponent}`)
        .then(function (response) {
          if (response.status === 200) {
            vm.listContract = response.data;
          }
          vm.isLoading = false;
        })
        .catch(function (err) {
          swal
            .mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 5000,
            })
            .fire({
              icon: 'error',
              title: err.response.data.message,
            });
          vm.isLoading = false;
        });
    },
    async insert() {
      let vm = this;
      let clientId = this.$route.params.id;
      if (this.$refs.form.validate()) {
        const params = new URLSearchParams();
        params.append('clientId', clientId);
        params.append('numero', vm.contract.numero);
        params.append('tipo', vm.contract.tipo);
        params.append('forma_pagamento', vm.contract.forma_pagamento);
        params.append('qtd_coleta_mes', vm.contract.qtd_coleta_mes);
        params.append('credito', vm.contract.credito);
        params.append(
          'valor_kg_exced',
          convertNumberToUsFormat(vm.contract.valor_kg_exced)
        );
        params.append('faturamento_inicial', vm.contract.faturamento_inicial);
        params.append('faturamento_periodo', vm.contract.faturamento_periodo);
        params.append('vencimento', vm.contract.vencimento);
        params.append('taxa', convertNumberToUsFormat(vm.contract.taxa));
        params.append('assinatura', vm.contract.assinatura);
        params.append('encerramento', vm.contract.encerramento);
        params.append('observacao', vm.contract.observacao);

        await api
          .post(this.urlBaseComponent, params)
          .then(function (response) {
            if (response.status === 201) {
              swal
                .mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 5000,
                })
                .fire({
                  icon: 'success',
                  title: 'Cadastrado com sucesso!',
                });
              vm.list();
              vm.reset();
            } else {
              swal
                .mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 5000,
                })
                .fire({
                  icon: 'error',
                  title: response.data.message,
                });
            }
          })
          .catch(function () {
            swal
              .mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 5000,
              })
              .fire({
                icon: 'error',
                title: 'Erro Inesperado.',
              });
          });
      }
    },
    async update() {
      let vm = this;
      swal
        .fire({
          title: 'Deseja atualizar este registro?',
          text: '',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })
        .then(async function (result) {
          if (result.value) {
            const params = new URLSearchParams();
            params.append('clientId', vm.contract.id);
            params.append('numero', vm.contract.numero);
            params.append('tipo', vm.contract.tipo);
            params.append('forma_pagamento', vm.contract.forma_pagamento);
            params.append('qtd_coleta_mes', vm.contract.qtd_coleta_mes);
            params.append('credito', vm.contract.credito);
            params.append(
              'valor_kg_exced',
              convertNumberToUsFormat(vm.contract.valor_kg_exced)
            );
            params.append(
              'faturamento_inicial',
              vm.contract.faturamento_inicial
            );
            params.append(
              'faturamento_periodo',
              vm.contract.faturamento_periodo
            );
            params.append('vencimento', vm.contract.vencimento);
            params.append('taxa', convertNumberToUsFormat(vm.contract.taxa));
            params.append('assinatura', vm.contract.assinatura);
            params.append('encerramento', vm.contract.encerramento);
            params.append('observacao', vm.contract.observacao);

            await api
              .put(`${vm.urlBaseComponent}/${vm.contract.id}`, params)
              .then(function (response) {
                if (response.status == 204) {
                  swal.fire(
                    'Atualizado!',
                    'Os dados foram atualizados com sucesso.',
                    'success'
                  );
                  vm.list();
                } else {
                  swal.fire('Falha!', response.data.message, 'warning');
                }
              })
              .catch(function () {
                swal
                  .mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 5000,
                  })
                  .fire({
                    icon: 'error',
                    title: 'Erro Inesperado.',
                  });
              });
          }
        });
    },
    async remove(id) {
      let vm = this;
      id = this.listContract.indexOf(id);
      swal
        .fire({
          title: 'Deseja excluir este registro?',
          text: 'Esta ação é irreversivel.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })
        .then(async function (result) {
          if (result.value) {
            await api
              .delete(`${vm.urlBaseComponent}/${vm.listContract[id].id}`)
              .then(function (response) {
                if (response.status === 200) {
                  vm.list();
                  swal.fire(
                    'Apagado!',
                    'A Informação foi apagada permanentemente.',
                    'success'
                  );
                } else {
                  swal
                    .mixin({
                      toast: true,
                      position: 'bottom-end',
                      showConfirmButton: false,
                      timer: 5000,
                    })
                    .fire({
                      icon: 'error',
                      title: response.data.message,
                    });
                }
              })
              .catch(function (e) {
                console.log(e.message);
                swal
                  .mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 5000,
                  })
                  .fire({
                    icon: 'error',
                    title: 'Erro Inesperado.',
                  });
              });
          }
        });
    },
    viewModal(type, id = 'null') {
      this.modalBtnCancel = 'Cancelar';
      if (type === 'insert') {
        this.modalTitle = 'Cadastrar Contrato';
      }
      if (type === 'update') {
        let l = this.listContract[this.listContract.indexOf(id)];
        this.modalTitle = 'Editar Contrato';
        this.contract.id = l.id;
        this.contract.numero = l.numero;
        this.contract.tipo = l.tipo;
        this.contract.forma_pagamento = l.forma_pagamento;
        this.contract.possui_credito = l.credito > 0 ? 1 : 0;
        this.contract.credito = l.credito;
        this.contract.taxa = l.taxa;
        this.contract.valor_kg_exced = l.valor_kg_exced;
        this.contract.vencimento = l.vencimento;
        this.contract.faturamento_inicial = l.faturamento_inicial;
        this.contract.faturamento_periodo = l.faturamento_periodo;
        this.contract.qtd_coleta_mes = l.qtd_coleta_mes;
        this.contract.assinatura = l.assinatura
          ? moment(l.assinatura).format('YYYY-MM-DD')
          : '';
        this.contract.encerramento = l.encerramento
          ? moment(l.encerramento).format('YYYY-MM-DD')
          : '';
        this.contract.observacao = l.observacao;
      }
      this.modalType = type;

      this.dialog = true;
    },
  },
  created: function () {
    this.list();
  },
  mounted: function () {},
  watch: {},
};
</script>
