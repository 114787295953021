<template>
  <v-card elevation="1" outlined tile height="100%">
    <v-toolbar dark color="grey" elevation="0" dense>
      <v-toolbar-title class="text-left"> Dados </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="grey darken-2"
        right
        rounded
        small
        class="sm-2 white--text"
        @click="viewModalContact('insert')"
      >
        <v-icon small left>mdi-plus-circle</v-icon>
        Novo Contato
      </v-btn>
    </v-toolbar>
    <v-card-text class="">
      <v-simple-table fixed-header height="260px">
        <template>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Contato</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, key) in listContacts" :key="item.contact_id">
              <td class="text-left">{{ item.category }}</td>
              <td class="text-center">{{ item.contact_value }}</td>
              <td class="text-right">
                <v-icon
                  small
                  class="mr-2"
                  @click="viewModalContact('update', key)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteContacts(key)"> mdi-delete </v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
      <v-card>
        <v-toolbar dark color="teal lighten-1" elevation="0">
          <v-toolbar-title class="">
            {{ modalTitle }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="4">
                  <v-select
                    v-model="contact.categoryId"
                    :items="listCategoryContact"
                    label="Tipo de Contato: *"
                    required
                    :rules="[v => !!v || 'Campo obrigatório']"
                    item-value="cat_contact_id"
                    item-text="cat_contact_name"
                    @change="typeCategory"
                  >
                  </v-select>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-if="categoryType == ''"
                    v-model="contact.value"
                    label="Selecione um tipo de contato"
                    disabled
                  ></v-text-field>
                  <v-text-field
                    type="phone"
                    v-if="categoryType == 'phone'"
                    v-model="contact.value"
                    label="Numero: *"
                    :rules="[v => !!v || 'Campo obrigatório']"
                    required
                    v-mask="['(##) ####-####', '(##) #####-####']"
                  ></v-text-field>
                  <v-text-field
                    type="mail"
                    v-if="categoryType == 'email'"
                    v-model="contact.value"
                    label="E-mail: *"
                    :rules="[
                      v =>
                        /^[a-z0-9.-_]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i.test(v) ||
                        'E-mail inválido'
                    ]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-if="categoryType == ''"
                    v-model="contact.observation"
                    label="Observação: "
                    disabled
                  >
                  </v-text-field>
                  <v-text-field
                    v-if="categoryType != ''"
                    v-model="contact.observation"
                    label="Observação: "
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*Campos obrigatórios</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="grey" small outlined reset @click="reset">
            {{ modalBtnCancel }}
          </v-btn>
          <v-btn
            dark
            rounded
            v-if="modalType == 'insert'"
            color="teal lighten-1"
            submit
            @click="insertContact"
          >
            Cadastrar
          </v-btn>
          <v-btn
            dark
            rounded
            v-else-if="modalType == 'update'"
            color="teal lighten-1"
            submit
            @click="contactUpdate(contact.listId)"
          >
            Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<style></style>
<script>
import swal from 'sweetalert2';
import api from '../plugins/Api';
import moment from 'moment';

export default {
  name: 'DadosContato',
  props: {},
  filters: {
    uppercase: function(text) {
      return text.toUpperCase();
    },
    dateFormat: function(date) {
      return date
        ? moment(date, 'YYYY-MM-DD T00:00:00').format('DD/MM/YYYY')
        : date;
    }
  },
  data: () => ({
    urlBaseComponent: '/contacts',
    isLoading: false,
    dialog: false,
    listContacts: [],
    listCategoryContact: [],
    modalTitle: '',
    modalType: '',
    modalBtnCancel: 'Cancelar',
    valid: true,
    contact: {
      value: '',
      categoryId: '',
      observation: '',
      listId: ''
    },
    categoryType: ''
  }),
  methods: {
    reset() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
    async getCategoryContact() {
      let vm = this;

      await api
        .get('/contact/category')
        .then(function(response) {
          if (response.status === 200) {
            vm.listCategoryContact = response.data;
          }
        })
        .catch(function() {
          swal
            .mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 5000
            })
            .fire({
              icon: 'error',
              title: 'Erro Inesperado.'
            });
        });
    },
    typeCategory(typeId) {
      let index = this.listCategoryContact.findIndex(
        e => e.cat_contact_id == typeId
      );
      this.categoryType = this.listCategoryContact[index].cat_contact_type;
    },
    async getContacts() {
      let vm = this;
      let clientId = this.$route.params.id;

      await api
        .get(`/clients/${clientId}${this.urlBaseComponent}`)
        .then(function(response) {
          if (response.status === 200) {
            vm.listContacts = response.data;
          }
        })
        .catch(function(err) {
          swal
            .mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 5000
            })
            .fire({
              icon: 'error',
              title: err.response.data.message
            });
        });
    },
    viewModalContact(type, id = 'null') {
      if (type === 'insert') {
        this.modalTitle = 'Cadastrar Contato';
      }
      if (type === 'update') {
        this.modalTitle = 'Editar Contato';
        this.contact.categoryId = this.listContacts[id].contact_categoryId;
        this.typeCategory(this.contact.categoryId);
        this.contact.value = this.listContacts[id].contact_value;
        this.contact.observation = this.listContacts[id].contact_observation;
        this.contact.listId = id;
      }
      this.modalType = type;
      this.dialog = true;
    },
    async insertContact() {
      let vm = this;
      let clientId = this.$route.params.id;
      if (this.$refs.form.validate()) {
        const params = new URLSearchParams();
        params.append('clientId', clientId);
        params.append('value', vm.contact.value);
        params.append('categoryId', vm.contact.categoryId);
        params.append('observation', vm.contact.observation);

        await api
          .post(vm.urlBaseComponent, params)
          .then(function(response) {
            if (response.status === 201) {
              swal
                .mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 5000
                })
                .fire({
                  icon: 'success',
                  title: 'Cadastrado com sucesso!'
                });
              vm.getContacts();
            } else {
              swal
                .mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 5000
                })
                .fire({
                  icon: 'error',
                  title: response.data.message
                });
            }
          })
          .catch(function(err) {
            swal
              .mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 5000
              })
              .fire({
                icon: 'error',
                title: err.response.data.message
              });
          });
      } else {
        swal
          .mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 5000
          })
          .fire({
            icon: 'error',
            title: 'Preencha o formulário com todos os dados obrigatórios.'
          });
      }
    },
    async contactUpdate(listId) {
      let vm = this;
      swal
        .fire({
          title: 'Deseja atualizar este registro?',
          text: 'Atualizar dados do contato.',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        })
        .then(async function(result) {
          if (result.value) {
            let contactId = vm.listContacts[listId].contact_id;

            const params = new URLSearchParams();
            params.append('value', vm.contact.value);
            params.append('categoryId', vm.contact.categoryId);
            params.append('observation', vm.contact.observation);

            await api
              .put(`${vm.urlBaseComponent}/${contactId}`, params)
              .then(function(response) {
                if (response.status === 204) {
                  swal.fire(
                    'Atualizado!',
                    'Os dados foram atualizados com sucesso.',
                    'success'
                  );
                  vm.getContacts();
                } else {
                  swal.fire('Falha!', response.data.message, 'warning');
                }
              })
              .catch(function(err) {
                console.log(err.response);
                swal
                  .mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 5000
                  })
                  .fire({
                    icon: 'error',
                    title: err.response.data.message
                  });
              });
          }
        });
    },
    async deleteContacts(index) {
      let vm = this;
      console.log(vm.listContacts[index].contact_id)
      swal
        .fire({
          title: 'Deseja excluir este registro?',
          text: 'Esta ação é irreversivel.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        })
        .then(async function(result) {
          if (result.value) {
            await api
              .delete(`${vm.urlBaseComponent}/${vm.listContacts[index].contact_id}`)
              .then(function(response) {
                if (response.status === 200) {
                  vm.listContacts.splice(index, 1);
                  swal.fire(
                    'Apagado!',
                    'A Informação foi apagada permanentemente.',
                    'success'
                  );
                }
              })
              .catch(function(err) {
                swal
                  .mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 5000
                  })
                  .fire({
                    icon: 'error',
                    title: err.response.data.message
                  });
              });
          }
        });
    }
  },
  created: function() {
    this.getContacts();
    this.getCategoryContact();
  }
};
</script>
