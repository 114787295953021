<template>
  <div>
    <v-navigation-drawer app v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item-group active-class="teal--text text--darken-4">
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar color="teal darken-4">
                <span class="white--text headline">D</span>
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ nome }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-divider></v-divider> -->

          <v-list-item
            link
            to="/dashboard"
            active-class="teal--text text--darken-4"
          >
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
          <!-- <v-divider /> -->
          <v-list-group prepend-icon="mdi-account-supervisor">
            <template v-slot:activator>
              <v-list-item-title>Clientes</v-list-item-title>
            </template>

            <v-list-item
              to="/cliente/pf"
              active-class="teal--text text--darken-4"
            >
              <v-list-item-icon>
                <v-icon>mdi-human-male-female-child</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Pessoa Física</v-list-item-title>
            </v-list-item>

            <v-list-item
              to="/cliente/pj"
              active-class="teal--text text--darken-4"
            >
              <v-list-item-icon>
                <v-icon>mdi-city</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Pessoa Jurídica</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <!-- <v-divider /> -->
          <v-list-group prepend-icon="mdi-cash">
            <template v-slot:activator>
              <v-list-item-title>Financeiro</v-list-item-title>
            </template>

            <v-list-item
              to="/contas-a-pagar"
              active-class="teal--text text--darken-4"
            >
              <v-list-item-icon>
                <v-icon>mdi-bank-transfer-out</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Contas a Pagar</v-list-item-title>
            </v-list-item>

            <v-list-item
              to="/contas-a-receber"
              active-class="teal--text text--darken-4"
            >
              <v-list-item-icon>
                <v-icon>mdi-bank-transfer-in</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Conta a Receber</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <!-- <v-divider /> -->

          <!-- <v-list-item active-class="teal--text text--darken-4">
            <v-list-item-icon>
              <v-icon>mdi-folder-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Contratos</v-list-item-title>
          </v-list-item> -->
          <!-- <v-divider /> -->
          <v-list-item
            to="/servicos"
            link
            active-class="teal--text text--darken-4"
          >
            <v-list-item-icon>
              <v-icon>mdi-hammer-wrench</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Serviços</v-list-item-title>
          </v-list-item>
          <!-- <v-divider /> -->
          <v-list-item to="/motoristas" link active-class="teal--text text--darken-4">
            <v-list-item-icon>
              <v-icon>mdi-clipboard-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Motoristas</v-list-item-title>
          </v-list-item>
          <!-- <v-divider /> -->
          <v-list-item to="/veiculos" link active-class="teal--text text--darken-4">
            <v-list-item-icon>
              <v-icon>mdi-car</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Veículos</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <!-- <v-divider /> -->
      </v-list>

      <template v-slot:append>
        <v-list dense>
          <v-list-group prepend-icon="mdi-gears">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Sistema</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item to="/acesso">
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Acesso</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-app-bar color="" app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Menu</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon title="Sair" link @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>
<style>
.v-avatar {
  min-width: auto !important;
}
</style>
<script>
import { signOut, getName } from '../functions/auth';
import router from '../router';
export default {
  name: 'MenuPrincipal',
  data: () => ({
    drawer: null,
    userAdm: false,
    nome: getName(),
  }),
  methods: {
    logout() {
      signOut();
      router.push('/login');
    },
  },
};
</script>
