import Vue from 'vue';
import VueRouter from 'vue-router';
import { isSignedIn } from '../functions/auth';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import ClientPF from '../views/ClientPF.vue';
import ClientPJ from '../views/ClientPJ.vue';
import ContasPagar from '../views/ContasPagar.vue';
import ContasReceber from '../views/ContasReceber.vue';
import Usuarios from '../views/Usuarios.vue';
// import Planos from '../views/Planos.vue';
// import Agents from '../views/Agents.vue';
// import Sellers from '../views/Sellers.vue';
import ClientDetail from '../views/ClientDetail.vue';
import Services from '../views/Services.vue';
import Drivers from '../views/Drivers.vue';
import Cars from '../views/Cars.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/dashboard'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'home',
    component: Home,
    meta: {
      navBarEnable: true,
      requiresAuth: true
    }
  },
  {
    path: '/servicos',
    name: 'services',
    component: Services,
    meta: {
      navBarEnable: true,
      requiresAuth: true
    }
  },
  {
    path: '/motoristas',
    name: 'drivers',
    component: Drivers,
    meta: {
      navBarEnable: true,
      requiresAuth: true
    }
  },
  {
    path: '/veiculos',
    name: 'cars',
    component: Cars,
    meta: {
      navBarEnable: true,
      requiresAuth: true
    }
  },
  {
    path: '/cliente/pf/detalhe/:id',
    name: 'ClientPFDet',
    component: ClientDetail,
    params: true,
    props: true,
    meta: {
      navBarEnable: true,
      requiresAuth: true
    }
  },
  {
    path: '/cliente/pf',
    name: 'ClientPF',
    component: ClientPF,
    meta: {
      navBarEnable: true,
      requiresAuth: true
    }
  },
  {
    path: '/cliente/pj/detalhe/:id',
    name: 'ClientPJDet',
    component: ClientDetail,
    params: true,
    props: true,
    meta: {
      navBarEnable: true,
      requiresAuth: true
    }
  },
  {
    path: '/cliente/pj',
    name: 'ClientPJ',
    component: ClientPJ,
    meta: {
      navBarEnable: true,
      requiresAuth: true
    }
  },
  {
    path: '/contas-a-pagar',
    name: 'ContaPagar',
    component: ContasPagar,
    meta: {
      navBarEnable: true,
      requiresAuth: true
    }
  },
  {
    path: '/contas-a-receber',
    name: 'ContaReceber',
    component: ContasReceber,
    meta: {
      navBarEnable: true,
      requiresAuth: true
    }
  },
  {
    path: '/acesso',
    name: 'Usuarios',
    component: Usuarios,
    meta: {
      navBarEnable: true,
      requiresAuth: true
    }
  },
  // {
    // path: '/planos',
    // name: 'Planos',
    // component: Planos,
    // meta: {
    //   navBarEnable: true,
    //   requiresAuth: true
    // }
  // },
  // {
  //   path: '/representante/:id',
  //   name: 'Sellers',
  //   component: Sellers,
  //   params: true,
  //   props: true,
  //   meta: {
  //     navBarEnable: true,
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/representantes',
  //   name: 'Agents',
  //   component: Agents,

  //   meta: {
  //     navBarEnable: true,
  //     requiresAuth: true
  //   }
  // }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path !== '/login') {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isAuth = isSignedIn();

    if (requiresAuth && !isAuth) next('/login');
    else if (requiresAuth && isAuth) next();
    else if (isAuth) next('dashboard');
    else next();
  }
  next();
});

export default router;
